import React, { Component } from 'react';

import { Formik } from 'formik';
import { Redirect } from 'react-router';

import Wrapper from '../../atoms/Wrapper';
import Button from '../../atoms/Button';

import MainTemplate from '../../templates/MainTemplate';
import RadioButtonsGroup from '../../atoms/Radio';

class SetupForm extends Component {
  constructor(props) {

    super(props);

    this.state = {
      hasError: false,
      success: false
    };
  }
  
  handleSubmit = async (values, actions) => {
    try {
      this.setState({ success: true });
    } catch (error) {
      actions.setSubmitting(false);
      if (error.message === "Network Error") {
        actions.setErrors({ status: 100 });
      } else {
        if (error.response) {
          if (error.response.status === 500) {
            actions.setErrors({ status: 500 });
          } else {
            actions.setErrors({ msg: error.response.data.errors[0].detail });
          }
        }
      }
    }
  };

  render() {
    const {
      success
    } = this.state;

    if (success) {
      return (<Redirect to={ "/programs"} />)
    } else {
    return (
      <MainTemplate>
        <Wrapper>
        <Formik
          initialValues={{ way: '', money: '', birthday: false, birthdayDiscount: '30', birthdayDiscountDays: '3', start_date: new Date(), expiry_date: null, kind: 'discount', type: 'allSales'}}
          onSubmit={this.handleSubmit}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleSubmit
        } = props;

        return (

          <form onSubmit={handleSubmit}>
            <h2>Правила дисконтной программы лояльности</h2>
            <RadioButtonsGroup
              id="way"
              label="За что поощрять ваших клиентов?"
              value={values.way}
              error={errors.way}
              touched={touched.way}
              options={[{id: "visits", label: 'Количество визитов'}, { id: "spends", label: 'Количество потраченных средств'}]}
            />
            <RadioButtonsGroup
              id="money"
              label="Какую сумму тратят ваши клиенты?"
              value={values.money}
              error={errors.money}
              touched={touched.money}
              options={[{id: "same", label: 'Примерно одинаковую'}, { id: "different", label: 'Каждый раз разные'}]}
            />
            <RadioButtonsGroup
              id="lp"
              label="У вас уже есть программа лояльности?"
              value={values.lp}
              error={errors.lp}
              touched={touched.lp}
              options={[{id: "no", label: 'Нет, я пробую впервые'}, { id: "import", label: 'Да, я бы хотел перенести базу клиентов и карт'}, { id: "new", label: 'Да, но я хочу запустить новую программу на виртуальных картах'}]}
            />
            
            <Button type="submit" disabled={isSubmitting} text="Продолжить" />
          </form>
        );
      }}
    </Formik>
    </Wrapper>
    <Wrapper>
      <p>
        <strong>Мы на связи</strong>
        <br/>
        Если у вас возникнут сложности или захотите задать вопрос, не стесняйтесь с нами связаться.
        <a href="t.me/loyltypro">Telegram</a>
      </p>
    </Wrapper>
    </MainTemplate>
    );
    }
  }
}
export default SetupForm;
