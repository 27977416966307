import React from 'react';
import styled from 'styled-components';
import Logo from '../../atoms/LogoWhite';
import bg from '../../../images/head.svg';

const Form = styled.div`
  @media screen and (min-width: 700px) {
    width: 40%;
    margin: 0 5%;
  }
`;

const Advantages = styled.div`
  ul {
    margin: 20px auto;
    max-width: 300px;
    list-style: none;
    padding-left: 10px;
    text-align: left;
  }

  li {
    margin-bottom: 20px;
  }

  @media screen and (min-width: 700px) {
    padding-top: 10%;
    width: 50%;
    background: #3f74ff;
    color: #fff;
    font-weight: 600;
    height: 100%;
    min-height: 100vh;
    justify-content: center;
    font-size: 25px;
    text-align: center;
    background-image: url(${bg});
    background-repeat: no-repeat;
    background-position: bottom center;

    @media screen and (prefers-color-scheme: dark) {
      min-height: calc(100vh - 40px);
      margin-left: 20px;
      border-radius: 10px;
      box-shadow: 0 7px 28px 0 rgba(0, 0, 0, 0.2);
    }
  }

  @media (max-width: 699px) {
    ul {
      display: none;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  flex-direction: column;

  @media screen and (min-width: 700px) {
    flex-direction: row;
    background: #fff;
    justify-content: center;
  }

  @media screen and (prefers-color-scheme: dark) {
    @media screen and (min-width: 700px) {
      background: #272727;
    }
  }

  @media screen and (max-width: 699px) {
    padding-top: 50px;
  }
  `;

const Footer = styled.div`
  padding: 20px 15px;
  z-index: 10;
  text-align: center;
  font-size: 13px;
  color: #777;

  @media screen and (min-width: 700px) and (min-height: 610px) {
    position: absolute;
    bottom: 0;
    left: 55%;
    right: 5%;
    z-index: 0;
  }
`;

const LoginTemplate = (props) => {
  const { children } = props;

  return (
    <Wrapper>
      <Advantages>
        <Logo />
        <ul>
          <li>Единая программа лояльности для сайта и&nbsp;точек продаж</li>
          <li>Гибкий механизм начисления бонусных баллов</li>
          <li>Детальная аналитика по&nbsp;работе программы лояльности</li>
        </ul>
      </Advantages>
      <Form>
        {children}
        <Footer>
          <p>© 2019 LoyaltyPro</p>
        </Footer>
      </Form>
    </Wrapper>
  );
};

export default LoginTemplate;
