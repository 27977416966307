import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import LoginTemplate from '../../templates/LoginTemplate';
import Button from '../../atoms/Button';
import Message from '../../atoms/Message';

const SignUpLink = styled.div`
  @media screen and (min-width: 700px) {
    position: absolute;
    right: 5%;
    top: 30px;

    .btn {
      font-size: 18px;
      border: solid 1px #3f74ff;
      padding: 10px 30px;
      border-radius: 5px;
      line-height: 32px;
      
      &:hover {
        background: #3f74ff;
        color: #fff;
      }
    }
  }

  @media screen and (max-width: 699px) {
    text-align: center;
    padding: 10px 0;
    margin: 30px -20px 0;
    background: #f7f7f7;
    border-top: solid 1px #f0f0f0;
  }
`;

const ErrorContainer = styled.div`
  margin-top: 5px;
  color: red;
`;

const Form = styled.form`
  @media screen and (max-width: 699px) {
    padding: 0 20px;
    background: #fff;
    box-shadow: 0 0 18px 0 rgba(0,0,0,0.1);
    overflow: hidden;
    margin-top: 20px;
    max-width: 380px;
  }

  @media (min-width: 320px) and (max-width: 699px) {
    border-radius: 3px;
  }
`;

class LoginForm extends Component {

  handleSubmit = async (values, actions) => {
    try {
    } catch (error) {
      actions.setSubmitting(false);

      if (error.message === "Network Error") {
        actions.setErrors({ status: 100 });
      } else {
        if (error.response.status === 500) {
          actions.setErrors({ status: 500 });
        } else {
          actions.setErrors({ auth: JSON.parse(error.response.request.response).errors[0].detail });
        }
      }
    }
  };

  render() {
    // const { match, location, history } = this.props

    return (
      <LoginTemplate>
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={this.handleSubmit}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Проверьте правильность адреса')
              .required('Без email никак'),
            password: Yup.string().required('Без пароля никак'),
          })}
        >
          {(props) => {
            const {
              values,
              errors,
              touched,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;
            return (
              <Form onSubmit={handleSubmit}>
                <Message text={errors.auth} status={errors.status} />
                {!errors.status && <div className='wrap'>
                <h2>Восстановление доступа</h2>
                <div className='form-group'>
                  <label htmlFor="last_name" className="label">Email</label>
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    autoFocus
                    autoComplete="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="field"
                  />
                  { touched.email && errors.email && !errors.password && <ErrorContainer>Проверьте правильность адреса</ErrorContainer>}
                </div>

                <Button type="submit" disabled={isSubmitting} text="Далее" />
                </div>}
                <SignUpLink><Link to="/login" className='btn'>Вход с паролем</Link></SignUpLink>
              </Form>
            );
          }}
        </Formik>

      </LoginTemplate>
    );
  }
}
export default LoginForm;
