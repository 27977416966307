import React, { Component } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (min-width: 880px) {
    .toggle {
      display: none;
    }
  }

  .toggle {
    cursor: pointer;
    vertical-align: middle;
    font-size: 16px;

    svg {
      vertical-align: text-bottom;
      margin-right: 5px;
    }
  }

  @media screen and (prefers-color-scheme: dark) {
    svg {
      fill: #fff;
    }
  }

  &.open .toggle {
    position: fixed;
    left: 0;
    width: 70px;
    top: 0;
    bottom: 0;
    z-index: 19;
    background: rgba(0, 0, 0, .3);
    text-indent: -999em;
    overflow: hidden;
  }

  &.open .nav {
    display: block;
    position: fixed;
    left: 70px;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 19;
    background: #fff;
    padding: 10px 0;
    box-shadow: 0 0 28px 0 rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 880px) and (prefers-color-scheme: dark) {
    &.open .nav {
      background: #383838;
    }
  }
`;

const Account = styled.div`
  margin-left: 30px;
  position: relative;
  margin-right: 30px;
  cursor: pointer;
  vertical-align: top;

  &:after {
    content: '';
    width: 6px;
    height: 6px;
    display: inline-block;
    border: solid 1px #000;
    border-width: 0 0 1px 1px;
    transform: rotate(-45deg);
    position: absolute;
    right: -14px;
    bottom: 6px;
  }

  @media (max-width: 840px) {
    margin-right: 10px;
    margin-left: 10px;
  }

  @media screen and (prefers-color-scheme: dark) {
    &:after {
      border-color: #fff;
    }
  }

  svg {
    vertical-align: middle;
    margin-right: 5px;

    @media (min-width: 1000px), (max-width: 500px) {
      display: none;
    }
  }

  .email {
    @media (max-width: 1000px) {
      display: none;
    }

    @media (max-width: 500px) {
      display: inline-block;
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: right;
    }
  }
`;

const Dropdown = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 8px 18px 0 rgba(88, 88, 88, 0.1);
  overflow: hidden;
  white-space: nowrap;

  a {
    display: block;
    padding: 7px 20px;

    &:hover {
      background: #3f74ff;
      color: #fff;
    }

    &:last-of-type {
      box-shadow: 0 -1px 0 0 #f0f0f0;
    }
  }

  @media screen and (prefers-color-scheme: dark) {
    background: #373737;
    box-shadow: inset 0 0 0 1px #404345, 0 8px 8px 0 rgba(0, 0, 0, 0.1);
    
    a {
      color: #fff;

      &:last-of-type {
        box-shadow: 0 -1px 0 0 #404345;
      }
    }
  }
`;

const Email = styled.div`
  padding: 7px 20px;
  cursor: default;
  color: #888;
  box-shadow: 0 1px 0 0 #f0f0f0;

  @media (min-width: 1090px), (max-width: 500px) {
    display: none;
  }

  @media screen and (prefers-color-scheme: dark) {
    box-shadow: 0 1px 0 0 #404345;
  }
`;

const Nav = styled.div`
  display: flex;
  flex-direction: row;

  a {
    line-height: 30px;
    display: inline-block;
    padding: 0 10px;
    margin: 0 5px;

    &.active {
      border-radius: 15px;
      color: #000;
      background: #fff;
    }
  }

  @media (max-width: 1090px) {
    a {
      margin: 0;
      font-size: 15px;
    }
  }

  @media (max-width: 880px) {
    display: none;

    a {
      display: block;
      font-size: 18px;
      padding: 3px 20px;
    }
  }

  @media screen and (prefers-color-scheme: dark) {
    a.active {
      color: #fff;
      background: #373737;
    }
  }
`;

class Navigation extends Component {
  constructor() {
    super();
    
    this.state = {
      showNav: false,
      showMobileNav: false
    };
    
    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  showMenu(event) {
    event.preventDefault();
    
    this.setState({ showNav: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }
  
  closeMenu() {
    this.setState({ showNav: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

  render() {
    const { confirmed, email } = this.props;
    const { showNav, showMobileNav } = this.state;
    if(confirmed) {
      return (
        <Wrap className={`${showMobileNav ? "open" : ""}`}>
          <span className='toggle' onClick={() => this.setState({ showMobileNav: !this.state.showMobileNav })}>
            <svg height='20px' width='15px'  fill="#000000" version="1.1" x="0px" y="0px" viewBox="0 0 16 15"><path d="M18,14 L18,17 L2,17 L2,14 L18,14 Z M18,8 L18,11 L2,11 L2,8 L18,8 Z M18,2 L18,5 L2,5 L2,2 L18,2 Z" /></svg>
            меню
          </span>
          <Nav className='nav'>
            <NavLink to="/customers" activeClassName="active">Клиенты</NavLink>
            <NavLink to="/cards" activeClassName="active">Карты</NavLink>
            <NavLink to="/widgets" activeClassName="active">Виджеты</NavLink>
            <NavLink to="/promocodes" activeClassName="active">Купоны на скидку</NavLink>
            <NavLink to="/transactions" activeClassName="active">Операции</NavLink>
            <NavLink to="/certificates" activeClassName="active">Сертификаты</NavLink>
            <NavLink to="/programs" activeClassName="active">Программа Лояльности</NavLink>
            <NavLink to="/reports" activeClassName="active">Отчёты</NavLink>
          </Nav>
          <Account>
            <span onClick={this.showMenu}>
              <svg height='16px' width='16px'  fill="#000000"  version="1.1" x="0px" y="0px" viewBox="0 0 100 100"><path d="M97.5,56.6V43.4l-10.9-2.7c-0.9-3.5-2.3-6.9-4.1-10l5.8-9.6l-9.4-9.4l-9.6,5.8c-3.1-1.8-6.4-3.2-10-4.1L56.6,2.5H43.4    l-2.7,10.9c-3.5,0.9-6.9,2.3-10,4.1l-9.6-5.8l-9.4,9.4l5.8,9.6c-1.8,3.1-3.2,6.4-4.1,10L2.5,43.4v13.2l10.9,2.7    c0.9,3.5,2.3,6.9,4.1,10l-5.8,9.6l9.4,9.4l9.6-5.8c3.1,1.8,6.4,3.2,10,4.1l2.7,10.9h13.2l2.7-10.9c3.6-0.9,6.9-2.3,10-4.1l9.6,5.8    l9.4-9.4l-5.8-9.6c1.8-3.1,3.2-6.4,4.1-10L97.5,56.6z M50,78.5c-15.7,0-28.5-12.8-28.5-28.5S34.3,21.5,50,21.5S78.5,34.3,78.5,50    S65.7,78.5,50,78.5z"></path></svg>
              <span className='email'>{email}</span>
            </span>
            {showNav && <Dropdown>
              <Email>{email}</Email>
              <NavLink to="/settings">Настройки</NavLink>
              <NavLink to="/company">Компания</NavLink>
              <NavLink to="/pos">Точки продаж</NavLink>
              <NavLink to="/integrations">Интеграции</NavLink>
              <a href='/login' className='logout' onClick={(e) => localStorage.clear()}>Выйти</a>
            </Dropdown>}
          </Account>
        </Wrap>
      );
    } else {
      return (
        <Wrap>
          <span>{email}</span>
          <a href='/login' className='logout' onClick={(e) => localStorage.clear()}>Выйти</a>
        </Wrap>
      );
    }
  }
};

export default Navigation;