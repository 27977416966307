import api from './ApiService';
import authService from './AuthService';
export default class DashboardService {

  static async fetchList(apiUrl) {
    const config = {
      headers: { Authorization: `Bearer ${authService.getToken()}` },
    };

    const response = await api.get("/" + apiUrl, config);
    return response;
  }

  static async createItem(apiUrl, values, relationships) {
    const config = {
      headers: { Authorization: `Bearer ${authService.getToken()}` },
    };
    const attributes = values;

    const response = await api.post("/" + apiUrl, { data: { attributes, relationships },  }, config);
    return response;
  }
}
