import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  margin-top: 5px;
  padding: 10px 30px;
  align-self: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  color: #fff;
  background-color: #3f74ff;
  border: none;
  outline: 0;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 30px;

  &:hover {
    background: #1f5dff;
  }

  @media screen and (max-width: 699px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const Button = (props) => {
  const { text, type } = props;
  return (
    <StyledButton type={type}>{ text }</StyledButton>
  );
};

export default Button;
