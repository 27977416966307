import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import CustomersPage from '../pages/Customers';
import ShowCustomerPage from '../pages/Customers/show';
import NewCustomerPage from '../pages/Customers/new';

import CertificatesPage from '../pages/Certificates';
import ShowCertificatePage from '../pages/Certificates/show';
import NewCertificatePage from '../pages/Certificates/new';

import PromoCodesPage from '../pages/Promocodes';
import ShowPromoCodePage from '../pages/Promocodes/show';
import NewPromoCodePage from '../pages/Promocodes/new';

import DashboardPage from '../pages/Dashboard';

import CompanyPage from '../pages/CompanyPage/show';
import PosPage from '../pages/PosPage';

import CardsPage from '../pages/Cards';
import ShowCardPage from '../pages/Cards/show';
import NewCardPage from '../pages/Cards/new';

import ProgramsPage from '../pages/Programs';
import NewDiscountProgramPage from '../pages/Programs/newDiscount';
import NewCumulativeProgramPage from '../pages/Programs/newCumulative';
import ShowProgramPage from '../pages/Programs/show';

import SettingsPage from '../pages/Settings';

import ReportsPage from '../pages/Reports';
import TransactionsPage from '../pages/Transactions';
import SetupPage from '../pages/Setup';

import ConfirmRouter from '../ConfirmRouter';
const Customers = () => <CustomersPage />;
const CustomerForm = (props)  => <NewCustomerPage id={props.match.params.id} />;
const ShowCustomer = (props) => <ShowCustomerPage id={props.match.params.id} />;

const Certificates = () => <CertificatesPage />;
const CertificateForm = ()  => <NewCertificatePage />;
const ShowCertificate = (props) => <ShowCertificatePage id={props.match.params.id} />;

const PromoCodes = () => <PromoCodesPage />;
const PromoCodeForm = ()  => <NewPromoCodePage />;
const ShowPromoCode = (props) => <ShowPromoCodePage id={props.match.params.id} />;

const Dashboard = () => <DashboardPage />;
const Pos = () => <PosPage />;
const Company = () => <CompanyPage />;

const CardForm = ()  => <NewCardPage />;
const Cards = () => <CardsPage />;
const ShowCard = (props) => <ShowCardPage id={props.match.params.id} />;

const NewDiscountForm = () => <NewDiscountProgramPage />;
const NewCumulativeForm = () => <NewCumulativeProgramPage />;
const Programs = () => <ProgramsPage />;
const ShowProgram = (props) => <ShowProgramPage id={props.match.params.id} />;

const Settings = () => <SettingsPage />;

const Reports = () => <ReportsPage />;
const Transactions = (props) => <TransactionsPage id={props.match.params.id} />;
const setupPage = () => <SetupPage />;

const Confirm = (props) => <ConfirmRouter id={props.match.params.id} />;

const AppRouter = (props) => (
  <Router>
    <Switch>
      <Route exact path="/" component={Dashboard} />
      <Route path="/company" component={Company} />
      
      <Route exact path="/customers/new/" component={CustomerForm} />
      <Route exact path="/customers/new/:id" component={CustomerForm} />
      <Route exact path="/customers/" component={Customers} />
      <Route exact path="/customers/:id" component={ShowCustomer} />

      <Route exact path="/certificates/new/" component={CertificateForm} />
      <Route exact path="/certificates/" component={Certificates} />
      <Route exact path="/certificates/:id" component={ShowCertificate} />

      <Route exact path="/promocodes/new/" component={PromoCodeForm} />
      <Route exact path="/promocodes/" component={PromoCodes} />
      <Route exact path="/promocodes/:id" component={ShowPromoCode} />

      <Route path="/pos" component={Pos} />

      <Route exact path="/cards/new/" component={CardForm} />
      <Route exact path="/cards" component={Cards} />
      <Route exact path="/cards/:id" component={ShowCard} />
      
      <Route exact path="/programs/" component={Programs} />
      <Route exact path="/programs/discount" component={NewDiscountForm} />
      <Route exact path="/programs/cumulative" component={NewCumulativeForm} />
      <Route exact path="/programs/:id" component={ShowProgram} />
      
      <Route exact path="/settings" component={Settings} />

      <Route exact path="/reports/" component={Reports} />
      <Route path="/transactions" component={Transactions} />
      <Route exact path="/setup/" component={setupPage} />

      <Route path="/confirm/:id" component={Confirm} />

      <Route path="*" component={Dashboard} />
    </Switch>
  </Router>
);

export default AppRouter;
