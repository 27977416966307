import React, { Component } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import MaskedInput from "react-text-mask";
import DataService from '../../../services/DataListService';
import Message from '../../atoms/Message';
import InlineError from '../../atoms/InlineError';
import Button from '../../atoms/Button';
import RadioButtonsGroup from '../../atoms/Radio';
import MainTemplate from '../../templates/MainTemplate';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';

class CustomerForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      customer: 0,
      id: this.props.id
    };
  }
  
  handleSubmit = async (values, actions) => {
    if (values.email === '' && values.phone === '') {
      actions.setErrors({ msg: 'Укажите email или телефон' });
      actions.setSubmitting(false);
    } else {
      let relationships = '';
      if (this.props.id) {
        relationships = { cards: { data: [{type: 'card', id: this.props.id}] } };
      } else {
        relationships = { cards: { data:[] }};
      }
      try {
        const response = await DataService.createItem("customers", values, relationships);
        if (response.status === 201) {
          this.setState({
            customer: response.data.data.id
          });
        }
      } catch (error) {
        actions.setSubmitting(false);
        if (error.message === "Network Error") {
          actions.setErrors({ status: 100 });
        } else {
          if (error.response) {
            if (error.response.status === 500) {
              actions.setErrors({ status: 500 });
            } else {
              actions.setErrors({ msg: error.response.data.errors[0].detail });
            }
          }
        }
      }
    }
  };

  render() {

    const {
      customer
    } = this.state;

    if (customer > 0) {
      return (<Redirect to={ "/customers/" + customer} />)
    } else {
    return (
      <MainTemplate>
        <Formik
          initialValues={{ first_name: '', last_name: '', email: '', phone: '', telegram: '', birthdate: '', gender: ''}}
          onSubmit={this.handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={Yup.object().shape({
            first_name: Yup.string().required('Заполните для обращений к клиентам по имени'),
            email: Yup.string().email('На этот адрес письмо не придёт'),
            gender: Yup.string().required('Выберите пол')
          })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit
        } = props;
        const id = this.props.id;
        const phoneNumberMask = [
          "(",
          /[1-9]/,
          /\d/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/
        ];

        return (

          <form className='form' onSubmit={handleSubmit}>
            {id ? (<h3>Новый владелец <Link to={{ pathname: '/cards/' + id }}>карты</Link></h3>) : (<h3>Новый клиент</h3>)}
            <Message text={errors.msg} status={errors.status} />
            {!this.state.hasError && <div className="wrap">

            <div className='form-group'>
              <label htmlFor="first_name" className="label">Имя</label>
              <Field
                id="first_name"
                name="first_name"
                type="text"
                value={values.first_name}
                onChange={handleChange}
                className={
                  errors.first_name && touched.first_name ? 'field error' : 'field'
                }
              />
              {errors.first_name && touched.first_name && <InlineError text={errors.first_name} />}
            </div>
            <div className='form-group'>
              <label htmlFor="last_name" className="label">Фамилия</label>
              <Field
                id="last_name"
                name="last_name"
                type="text"
                placeholder="необязательно"
                value={values.last_name}
                onChange={handleChange}
                className={
                  errors.last_name && touched.last_name ? 'field error' : 'field'
                }
              />
              {errors.last_name
                && touched.last_name && <InlineError text={errors.last_name} />}
            </div>

            <div className='form-group'>
              <label htmlFor="email" className="label">Email</label>
              <Field
                id="email"
                name="email"
                type="email"
                value={values.email}
                onChange={handleChange}
                className={
                  errors.email && touched.email ? 'field error' : 'field'
                }
              />
              {errors.email
                && touched.email && <InlineError text={errors.email} />}
            </div>
            <div className='form-group'>
              <label htmlFor="phone" className="label">Телефон</label>
              <Field
                name="phone"
                render={({ field }) => (
                  <MaskedInput
                    {...field}
                    mask={phoneNumberMask}
                    id="phone"
                    placeholder="в любом формате"
                    type="text"
                    inputMode="numeric"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.phone && touched.phone
                        ? "field error field--sm"
                        : "field field--sm"
                    }
                  />
                )}
              />
              {errors.phone
                && touched.phone && <InlineError text={errors.phone} />}
            </div>

            <div className='form-group'>
              <label htmlFor="telegram" className="label">Telegram</label>
              <Field
                id="telegram"
                name="telegram"
                type="text"
                placeholder="@"
                value={values.telegram}
                onChange={handleChange}
                className={
                  errors.telegram && touched.telegram ? 'field error field--sm' : 'field field--sm'
                }
              />
              <small className='hint'>Можно получать информация от бота</small>
              {errors.telegram
                && touched.telegram && <InlineError text={errors.telegram} />}
            </div>

            <div className='form-group'>
              <label htmlFor="birthdate" className="label">День рождения</label>
              <Field
                id="birthdate"
                name="birthdate"
                type="date"
                value={values.birthdate}
                onChange={handleChange}
                className={
                  errors.birthdate && touched.birthdate ? 'field error field--sm' : 'field field--sm'
                }
              />
              <small className='hint'>для дополнительных бонусов/скидок в дни рождения</small>
              {errors.birthdate
                && touched.birthdate && <InlineError text={errors.birthdate} />}
            </div>
            <RadioButtonsGroup
              id="gender"
              label="Пол"
              value={values.gender}
              error={errors.gender}
              touched={touched.gender}
              options={[{id: "female", label: 'Женский'}, { id: "male", label: 'Мужской'}]}
            />
            <Button type="submit" disabled={isSubmitting} text="Добавить" /></div>}
          </form>
        );
      }}
    </Formik>
      </MainTemplate>
    );
    }
  }
}
export default CustomerForm;
