import React from 'react';
import styled from 'styled-components';

const Card = styled.span`
  font-size: 16px;
  color: #777;
  width: 48%;
  margin-right: 2%;
  margin-bottom: 10px;
  display: inline-block;
  box-sizing: border-box;
  padding: 10px;

  h4 {
    font-size: 22px;
    color: #000;
    margin: 0;
  }

  .grid-3 & {
    max-width: 30%;
  }

  @media (prefers-color-scheme: dark) {
    color: #e1e1e1;

    h4 {
      color: #fff;
    }
  }
`;

const ChartBox = (props) => {
  const { title, value } = props;
  return (
    <Card>
      { title }
      <h4>{ value }</h4>
    </Card>
  );
};

export default ChartBox;
