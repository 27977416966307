import React, { Component } from 'react';

import { Formik, Field, FieldArray } from 'formik';
import { Redirect } from 'react-router';
import * as Yup from 'yup';
import Message from '../../atoms/Message';
import InlineError from '../../atoms/InlineError';

import Button from '../../atoms/Button';
import DateRange from '../../molecules/DateRange';
import MainTemplate from '../../templates/MainTemplate';
import RadioButtonsGroup from '../../atoms/Radio';

class ProgramForm extends Component {
  constructor(props) {

    super(props);

    this.state = {
      hasError: false,
      success: false
    };
  }
  
  handleSubmit = async (values, actions) => {
    try {
      this.setState({ success: true });
    } catch (error) {
      actions.setSubmitting(false);
      if (error.message === "Network Error") {
        actions.setErrors({ status: 100 });
      } else {
        if (error.response) {
          if (error.response.status === 500) {
            actions.setErrors({ status: 500 });
          } else {
            actions.setErrors({ msg: error.response.data.errors[0].detail });
          }
        }
      }
    }
  };

  render() {
    const {
      success
    } = this.state;

    if (success) {
      return (<Redirect to={ "/programs"} />)
    } else {
    return (
      <MainTemplate>
        <Formik
          initialValues={{ name:'', discount: [''], birthday: false, birthdayDiscount: '30', birthdayDiscountDays: '3', start_date: new Date(), expiry_date: null, kind: 'discount', type: 'allSales'}}
          onSubmit={this.handleSubmit}
          validationSchema={Yup.object().shape({
            balance: Yup.number('только числа'),
            discount: Yup.number('только числа')
          })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleSubmit
        } = props;

        return (

          <form className='form' onSubmit={handleSubmit}>
            <h2>Правила дисконтной программы лояльности</h2>
            <Message text={errors.msg} status={errors.status} />
            {!this.state.hasError && <div className="wrap">
            <div className='form-group'>
              <label htmlFor="expiry_date" className="label">Название</label>
              <Field
                id="name"
                name="name"
                type="text"
                value={values.expiry_date}
                onChange={handleChange}
                className={
                  errors.name && touched.name ? 'field error' : 'field'
                }
              />
              {errors.name
                && touched.name && <InlineError text={errors.name} />}
            </div>
            <div className='form-group'>
              <label className="label">Период активности</label>
              <DateRange />
            </div>
            <br/>
            <RadioButtonsGroup
              id="type"
              label="Увеличение скидки"
              value={values.type}
              error={errors.type}
              touched={touched.type}
              options={[{id: "allSales", label: 'от суммы всех покупок'}, { id: "singleSale", label: 'только от разовой покупки'}]}
            />

            <div className='form-group'>
              <label htmlFor="levels" className="label">Группы скидок</label>
              <label className="field--xs">Скидка</label>
              <label className="field--sm">Порог смены скидки</label>
            </div>
            <FieldArray
              name="discount"
              render={arrayHelpers => (
                <div className='nested-group'>
                  {values.discount.map((index) => (
                    <div className='form-group' key={index}>
                      <Field name={`discount[${index}].size`} placeholder='%' className='field field--xs' />
                      <Field name={`discount.${index}.sum`} placeholder='руб' className='field field--sm' />
                      <button type="button" onClick={() => arrayHelpers.remove(index)}>
                        -
                      </button>
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={() => arrayHelpers.push({ size: '', sum: '' })}
                  >
                    +
                  </button>
                </div>
              )}
            />

            <div className='form-group'>
              <Field
                id="birthday"
                name="birthday"
                type="checkbox"
                value={values.birthday}
                onChange={handleChange}
              />
              <label htmlFor="birthday" className="label">Повышенная скидка в день рождения</label>
            </div>
            {values.birthday &&
            <div className='birthday'>
              <div className='form-group'>
                <label htmlFor="birthdayDiscount" className="label">Скидка в день рождения</label>
                <Field
                  id="birthdayDiscount"
                  placeholder="%"
                  name="birthdayDiscount"
                  type="number"
                  size="3"
                  max="100"
                  value={values.birthdayDiscount}
                  onChange={handleChange}
                  className={
                    errors.birthdayDiscount && touched.birthdayDiscount ? 'field error' : 'field field--xs'
                  }
                />
                <small className='hint'>Не суммируется со скидкой по карте.</small>
              </div>
              <div className='form-group'>
                <label htmlFor="birthdayDiscountDays" className="label">Активность</label>
                <Field
                  id="birthdayDiscountDays"
                  placeholder="%"
                  name="birthdayDiscountDays"
                  type="number"
                  size="3"
                  value={values.birthdayDiscountDays}
                  onChange={handleChange}
                  className={
                    errors.birthdayDiscountDays && touched.birthdayDiscountDays ? 'field error' : 'field field--xs'
                  }
                />
                <small className='hint'>В день рождения и {values.birthdayDiscountDays} дня после дня рождения будет доступна скидка {values.birthdayDiscount}%.</small>
              </div>
            </div>}
            <Button type="submit" disabled={isSubmitting} text="Сохранить" /></div>}
          </form>
        );
      }}
    </Formik>
      </MainTemplate>
    );
    }
  }
}
export default ProgramForm;
