import React from 'react';
import List from '../../organisms/List/static';
import MainTemplate from '../../templates/MainTemplate';

const DataSource = {"data": [{"type": "promocode", "id": "1", "attributes": {"name": "Новый год", "code": "MOROZ", "amount": "10%", "sold": "10", "activated": "3", "source": "магазины", "kind": "plastic", "created_at": "2020-01-06T19:24:14.234Z"}, }, {"type": "promocode", "id": "2", "attributes": {"name": "23 февраля", "code": "SUPERHERO", "amount": "10%", "sold": "0", "activated": "0", "source": "магазины", "kind": "plastic", "created_at": "2020-01-06T19:24:14.234Z"}, }, {"type": "promocode", "id": "3", "attributes": {"name": "8 Марта", "code": "LADIES", "amount": "8%", "sold": "0", "activated": "0", "source": "магазины", "kind": "plastic", "created_at": "2020-01-06T19:24:14.234Z"}, }, {"type": "promocode", "id": "4", "attributes": {"name": "День рождение компании", "code": "HB10", "amount": "10%", "sold": "0", "activated": "0", "source": "магазины", "kind": "plastic", "created_at": "2020-01-06T19:24:14.234Z"}, }, {"id": "5", "type": "promocode", "attributes": {"name": "Активация клиентов", "code": "", "amount": 300, "sold": "10", "activated": "5", "source": "интернет-магазин", "kind": "digital", "created_at": "2020-01-08T07:41:05.452Z"}, }, {"id": "6", "type": "promocode", "attributes": {"name": "Площадка КупиКупон", "code": "", "amount": 100, "sold": "5", "activated": "3", "source": "интернет-магазин", "kind": "digital", "created_at": "2020-01-08T07:41:05.452Z"}, }, {"id": "7", "type": "promocode", "attributes": {"name": "Отзыв о магазине", "code": "", "amount": 300, "sold": "3", "activated": "1", "source": "интернет-магазин", "kind": "digital", "created_at": "2020-01-08T07:41:05.452Z"}, } ] }
// const DataSource = {"data":[]}
const PromoCodesPage = () => (
  <MainTemplate>
    <List className='scrollable' data={DataSource} options={['']} items="promocodes" title="Купоны на скидку" headers={["Название акции", "Код", "скидка", "макс. кол-во", "использовано", "ограничения", "тип", "дата создания"]} actions={true}>
      <h2>Купоны на скидку</h2>
      <p>Откройте в магазине распродажу или запустите специальное предложение на избранные товары с помощью купонов на скидку.</p>
      <p>Вы можете предлагать скидку фиксированного размера, в процентах от стоимости заказа или в виде бесплатной доставки.</p>
      <p>Купон на скидку — это секретный код, который покупатель может указать в процессе покупки, чтобы получить скидку.</p>
      <p>Скидка применяется к цене товаров без учёта налогов. Скидка не распространяется на доставку и не может превышать стоимость товаров.</p>
    </List>
  </MainTemplate>
);

export default PromoCodesPage;
