import React from 'react';
import QRCode from 'qrcode.react';
import styled from 'styled-components';

const Token = styled.div`
  font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
  background: #f7f5f5;
  padding: 3px 10px;
  margin-top: -20px;
  margin-right: -10px;
  border-radius: 5px;

  span {
    font-family: -apple-system, "Segoe UI", "Roboto","Helvetica Neue", sans-serif;
    text-decoration: underline;
    color: #3f74ff;
    margin-left: 5px;
    white-space: nowrap;
    cursor: pointer;
  }

  @media screen and (prefers-color-scheme: dark) {
    background: none;
  }

  @media (max-width: 650px) {
    font-size: 13px;
    margin: 10px 0;
    padding: 3px 5px;
  }

  @media (min-width: 480px) {
    display: inline-block;

    span {
      display: none;
    }
  }

  @media (min-width: 680px) {
    float: right;
  }

  .card-preview & {
    float: none;
    font-size: 13px;
    padding: 0;
    margin-bottom: 5px;
  }
`;

const Dates = styled.div`
  span {
    white-space: nowrap;
    display: inline-block;
  }
`;

const Code = styled.div`  
.dark {
  display: none;
}

label {
    margin-top: 10px;
    display: block;
    text-align: center;
    background: #111;
    padding: 2px 0;
    color: #fff;
    max-width: 380px;
    margin: auto;
    font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
  }

  label:empty {
    display: none;
  }

  .card-preview & {
    margin-bottom: 0;
    margin-right: 15px;
  }

  @media screen and (prefers-color-scheme: dark) {
    .dark {
      display: block;
    }

    .light {
      display: none;
    }

    label {
      background: #fff;
      color: #111;
    }
  }

  @media (min-width: 480px) {
    float: left;
    margin: 0 30px 30px 0;
  }

  @media (max-width: 479px) {
    margin: auto;
    display: none;

    canvas {
      min-width: 100%;
      height: auto !important;
    }

    .card-preview & {
      margin: auto;
    }

    .open & {
      display: block;
    }
  }
`;

class cardPreview extends React.Component {
  constructor(props) {

    super(props);

    this.state = {
      showCode: false,
    };
  }

  render() {
    const { id, attributes, kind, size, ...props } = this.props;

    return (
      <div {...props} className={`${this.state.showCode ? (kind + " open") : kind}`}>
        <Token>
          {id}
          <span onClick={() => this.setState({ showCode: !this.state.showCode })}>{this.state.showCode ? 'скрыть' : 'показать QR-код карты' }</span>
        </Token>
        <Code title={id}>
          <QRCode className='light' value={id} alt={id} size={size} title={id} bgColor='transparent' fgColor='#000000'/>
          <QRCode className='dark' value={id} alt={id} size={size} title={id} bgColor='#2e2e2e' fgColor='#ffffff' />
          <br/>
          {attributes && <label>{attributes.code}</label>}
        </Code>
        {attributes && <h3>
          {attributes.kind === 'discount' && <span>Дисконтная карта <strong>скидка {attributes.discount}%</strong></span>}
          {attributes.kind === 'cumulative' && <span>Накопительная карта <strong>{attributes.balance} баллов</strong></span>}
        </h3>}
        {attributes && <Dates>
          Карта активна <span>с {Intl.DateTimeFormat('ru-RU', {month: 'long', day: 'numeric', year: 'numeric'}).format(new Date(attributes.created_at))} </span>
          {attributes.expiry_date && <span> по {Intl.DateTimeFormat('ru-RU', {month: 'long', day: 'numeric', year: 'numeric'}).format(new Date(attributes.expiry_date))} </span>}
          
        </Dates>}
      </div>
    );
  }
};

export default cardPreview;
