import React, { Component } from 'react';
import { Formik, Field } from 'formik';
import { Redirect } from 'react-router';
import * as Yup from 'yup';

import DataService from '../../../services/DataListService';
import Message from '../../atoms/Message';
import InlineError from '../../atoms/InlineError';
import Button from '../../atoms/Button';
import MainTemplate from '../../templates/MainTemplate';
import RadioButtonsGroup from '../../atoms/Radio';

class CustomerForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      success: false
    };
  }

  handleSubmit = async (values, actions) => {
    try {
      const formValues = { cards: [{ kind: values.kind, discount: values.discount, balance: values.balance, code: values.code, expiry_date: values.expiry_date }] };
      const response = await DataService.createItem("cards_uploads", formValues );
      if (response.status === 201) {
        this.setState({ success: true });
      }
    } catch (error) {
      actions.setSubmitting(false);
      if (error.message === "Network Error") {
        actions.setErrors({ status: 100 });
      } else {
        if (error.response) {
          if (error.response.status === 500) {
            actions.setErrors({ status: 500 });
          } else {
            actions.setErrors({ msg: error.response.data.errors[0].detail });
          }
        }
      }
    }
  };

  render() {
    const {
      success
    } = this.state;

    if (success) {
      return (<Redirect to={ "/certificates"} />)
    } else {
    return (
      <MainTemplate>
        <Formik
          initialValues={{ discount: '', code: '', expiry_date: '', kind: 'digital', description: ''}}
          onSubmit={this.handleSubmit}
          validationSchema={Yup.object().shape({
            balance: Yup.number('только числа'),
            discount: Yup.number('только числа')
          })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleSubmit
        } = props;

        return (

          <form className='form' onSubmit={handleSubmit}>
            <h3>Новый подарочный сертификат</h3>
            <Message text={errors.msg} status={errors.status} />
            {!this.state.hasError && <div className="wrap">
            <RadioButtonsGroup
              id="kind"
              label="Тип сертификата"
              value={values.kind}
              error={errors.kind}
              touched={touched.kind}
              options={[{id: "digital", label: 'Электронный'}, { id: "plastic", label: 'Пластиковая карта'}]}
            />
            <div className='form-group'>
              <label htmlFor="title" className="label">Название</label>
              <Field
                placeholder=""
                name="title"
                id="title"
                type="text"
                value={values.code}
                onChange={handleChange}
                className={
                  errors.code && touched.code ? 'field error' : 'field field--sm'
                }
              />
              {errors.code
                && touched.code && <InlineError text={errors.code} />}
            </div>
            <div className='form-group'>
              <label htmlFor="discount" className="label">Номинал сертификата</label>
              <Field
                id="discount"
                name="discount"
                type="number"
                value={values.discount}
                onChange={handleChange}
                className={
                  errors.discount && touched.discount ? 'field error' : 'field field--sm'
                }
              />
              {errors.discount
                && touched.discount && <InlineError text={errors.discount} />}
            </div>
            <div className='form-group'>
              <label htmlFor="area" className="label">Точки продаж</label>
              <Field
                id="area"
                name="area"
                component="select"
                value={values.area}
                onChange={handleChange}
                className='field'
              >
                <option value="web">интернет-магазин</option>
                <option value="offline">розничные точки</option>
                <option value="partner">партнер #1</option>
              </Field>
              {errors.area
                && touched.area && <InlineError text={errors.area} />}
            </div>
            {values.kind === 'digital' && <div className='form-group'>
              <label htmlFor="count" className="label">Тираж</label>
              <Field
                id="count"
                placeholder=""
                name="count"
                type="number"
                value={values.count}
                onChange={handleChange}
                className={
                  errors.count && touched.count ? 'field error' : 'field field--sm'
                }
              />
              {errors.count
                && touched.count && <InlineError text={errors.count} />}
            </div>}
            {values.kind === 'digital' && <div className='form-group'>
              <label htmlFor="description" className="label">Описание</label>
              <Field
                id="description"
                name="description"
                component="textarea"
                value={values.code}
                onChange={handleChange}
                className={
                  errors.description && touched.description ? 'field error' : 'field'
                }
              />
              {errors.description
                && touched.description && <InlineError text={errors.description} />}
            </div>}
            <Button type="submit" disabled={isSubmitting} text={values.kind === 'digital' ? "Выпустить" : "Продолжить"} /></div>}
          </form>
        );
      }}
    </Formik>
      </MainTemplate>
    );
    }
  }
}
export default CustomerForm;
