import React from 'react';
import styled from 'styled-components';
import Emoji from '../Emoji';

const Wrap = styled.div`
  font-size: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  p {
    padding-left: 7px;
  }
`;

const Msg = styled.div`
  color: #000;
  align-self: center;
  background: #fff;
  padding: 50px 20px;
  max-width: 320px;
  box-shadow: 0 7px 28px 0 rgba(0, 0, 0, 0.1);

  h1 {
    font-size: 18px;
    margin-top: 0;
    display: inline;
  }

  span[role=img] {
    float: left;
    margin-right: 10px;
    margin-top: -15px;
    margin-left: 5px;
    height: 65px;
  }

  @media screen and (prefers-color-scheme: dark) {
    background-color: #2e2e2e;
    box-shadow: 0 7px 28px 0 rgba(0, 0, 0, 0.2);
    color: #fff;
  }
`;

const Button = styled.button`
  color: #3f74ff;
  padding: 0;
  background: none;
  font-size: 16px;
  border: 0;
  cursor: pointer;
`;

const ErrorMessage = styled.div`
  color: red;
  padding-top: 10px;

  a {
    font-weight: bold;
    color: red;
    text-decoration: underline;
  }
`;

const Message = (props) => {
  const { text, status } = props;
  return (
    <span>
      {status === 100 && <Wrap><Msg>
      <Emoji icon="🔌" size='xxx-large' label="Личный кабинет временно недоступен" />
      <h1>Нет связи с&nbsp;сервером</h1>
      <p>
        Проверьте подключение к&nbsp;Интернету и <Button onClick={() => window.location.reload()}>обновить страницу</Button>
      </p></Msg></Wrap>
      }
      {status === 500 && 
        <Wrap><Msg>
          <Emoji icon="⚠" size='55px' label="Личный кабинет временно недоступен" />
          <h1>Личный кабинет временно недоступен</h1>
          <p>Приносим извинения за доставленные неудобства.</p>
          <p>Попробуйте <Button onClick={() => window.location.reload()}>обновить страницу</Button> чуть позже.</p>
        </Msg></Wrap>
      }
      <ErrorMessage dangerouslySetInnerHTML={{__html: text}}></ErrorMessage>

    </span>
  );
};

export default Message;
