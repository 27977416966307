import React from 'react';
import styled from 'styled-components';

const ErrorContainer = styled.span`
  margin-top: 5px;
  color: red;
  width: 100%;

  @media (min-width: 768px) {
    margin-left: 220px;
  }
`;

const InlineError = (props) => {
  const { text } = props;
  return (
    <ErrorContainer>{ text }</ErrorContainer>
  );
};

export default InlineError;
