import React, { Component } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import AuthService from '../../../services/AuthService';
import DataService from '../../../services/DataListService';
import Message from '../../atoms/Message';
import InlineError from '../../atoms/InlineError';
import Button from '../../atoms/Button';
import MainTemplate from '../../templates/MainTemplate';

class SettingsForm extends Component {
  constructor() {
    super();

    this.state = {
      isLoading: true
    };

    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.updateItem(this.props.items);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateItem = async () => {
    const id = AuthService.getUserId();
    try {
      const data = await DataService.fetchList('users/' + id.user_id);

      if (this._isMounted) {
        this.setState({User: data.data.data.attributes, isLoading: false});
      }
    } catch (error) {}
  };

  
  handleSubmit = async (values, actions) => {
    try {
    } catch (error) {
      if (error.message === "Network Error") {
        actions.setErrors({ status: 100 });
      } else {
        if (error.response) {
          if (error.response.status === 500) {
            actions.setErrors({ status: 500 });
          } else {
            actions.setErrors({ auth: error.response.data.errors[0].detail });
          }
        }
      }
      actions.setSubmitting(false);
    }
  };

  render() {
    const {
      User, isLoading
    } = this.state;
    return (
      <MainTemplate>
      {!isLoading && 
      <Formik
      initialValues={{ full_name: User.full_name, email: User.email }}
      onSubmit={this.handleSubmit}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Чтобы ваши клиенты узнали вас, можно изменить в любое время'),
        email: Yup.number().required('Обязательно').min(10, 'Проверьте количество цифр')
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleSubmit
        } = props;
        
        return (
          <form className='form' onSubmit={handleSubmit}>
            <Message text={errors.auth} status={errors.status} />
            {!this.state.hasError && <div className="wrap">
            <div className='form-group'>
              <label htmlFor="full_name" className="label">Полное имя</label>
              <Field
                id="full_name"
                name="full_name"
                type="text"
                autoFocus
                value={values.full_name}
                onChange={handleChange}
                className={
                  errors.name && touched.name ? 'field error' : 'field'
                }
              />
              {errors.name
                && touched.name && <InlineError text={errors.name} />}
            </div>

            <div className='form-group'>
              <label htmlFor="email" className="label">Email</label>
              <Field
                id="email"
                name="email"
                type="email"
                value={values.email}
                disabled="disabled"
                className='field'
              />
            </div>
            <div className='form-group'>
              <label htmlFor="current_password" className="label">Текущий пароль</label>
              <Field
                autoComplete=""
                id="current_password"
                name="current_password"
                type="password"
                className='field'
              />
            </div>
            <Button type="submit" disabled={isSubmitting} text="Сохранить" /></div>}
          </form>
        );
      }}
    </Formik>}
    </MainTemplate>
    );
  }
}
export default SettingsForm;
