import React from 'react';
import Emoji from '../../atoms/Emoji';
import List from '../../organisms/List';
import MainTemplate from '../../templates/MainTemplate';

const CustomersPage = () => (
  <MainTemplate>
    <List className='collapsable' items="customers" title="Клиенты" options={['', '', '', '', '', {'male':'Мужской', 'female':'Женский'}, '', '', '']} headers={["Имя", "Фамилия", "Email", "Телефон", "День рождения", "Пол", "Telegram", "Зарегистрирован", "Кол-во покупок"]} actions={true}>
      <Emoji icon="👀" size='xxx-large' label="Клиентов ещё нет" />
      <h2>Клиентов ещё нет</h2>
      <p>Регистрируйте ваших клиентов в системе и вознаграждайте их бонусами, скидками и акциями</p>
    </List>
  </MainTemplate>
);

export default CustomersPage;
