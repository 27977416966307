import React, { Component } from 'react';

import styled from 'styled-components';

const Inliner = styled.div`
  position: relative;
  display: inline-block;
  z-index: 9;
`;

const Options = styled.div`
  position: absolute;
  top: 5px;
  right: -10px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 8px 18px 1px rgba(88, 88, 88, 0.1);
  border: solid 1px #f0f0f0;
  white-space: nowrap;
  overflow: hidden;

  button,
  a {
    display: block;
    padding: 7px 20px;
    border: 0;
    background: none;
    margin: 0;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      background: #3f74ff;
      color: #fff;
    }
  }

  .inactive {
    color: #009d64;
    font-weight: bold;
  }

  .active {
    color: #e64300;
    font-weight: bold;
  }

  @media screen and (prefers-color-scheme: dark) {
    background: #373737;
    box-shadow: inset 0 0 0 1px #404345, 0 8px 8px 1px rgba(0, 0, 0, 0.1);
    border: 0;

    a {
      color: #fff;
    }
  }
`;

class Dropdown extends Component {
  constructor() {
    super();
    
    this.state = {
      showNav: false,
    };
    
    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  showMenu(event) {
    event.preventDefault();
    
    this.setState({ showNav: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }
  
  closeMenu() {
    this.setState({ showNav: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }
  
  render() {
    const { active, inactive, actions } = this.props;
    const { showNav } = this.state;
    return (
      <>
        <span onClick={this.showMenu} className={this.state.enabled ? 'dropdown inactive' : 'dropdown active'}>
          {this.state.enabled ? inactive : active}
        </span>
        <Inliner>
        {showNav && <Options>
                      <button className={this.state.enabled ? 'inactive' : 'active'} onClick={() => this.setState({ enabled: !this.state.enabled, showNav: !this.state.showNav })}>
                        {this.state.enabled ? actions[1] : actions[0]}
                      </button>
                    </Options>}
        </Inliner>
      </>
    );  
  }
};

export default Dropdown;