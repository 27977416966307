import React, { Component } from 'react';
import { Formik, Field } from 'formik';
import { Redirect } from 'react-router';
import * as Yup from 'yup';

import DataService from '../../../services/DataListService';
import Message from '../../atoms/Message';
import InlineError from '../../atoms/InlineError';
import Button from '../../atoms/Button';
import MainTemplate from '../../templates/MainTemplate';
import RadioButtonsGroup from '../../atoms/Radio';


class CustomerForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      success: false
    };
  }
  
  handleSubmit = async (values, actions) => {
    try {
      if (values.kind === 'cumulative') {
        values.discount = 0;
      } else {
        values.balance = 0;
      }
      const formValues = { cards: [{ kind: values.kind, discount: values.discount, balance: values.balance, code: values.code, expiry_date: values.expiry_date }] };
      const response = await DataService.createItem("cards_uploads", formValues );
      if (response.status === 201) {
        this.setState({ success: true });
      }
    } catch (error) {
      actions.setSubmitting(false);
      if (error.message === "Network Error") {
        actions.setErrors({ status: 100 });
      } else {
        if (error.response) {
          if (error.response.status === 500) {
            actions.setErrors({ status: 500 });
          } else {
            actions.setErrors({ msg: error.response.data.errors[0].detail });
          }
        }
      }
    }
  };

  render() {
    const {
      success
    } = this.state;

    if (success) {
      return (<Redirect to={ "/cards"} />)
    } else {
    return (
      <MainTemplate>
        <Formik
          initialValues={{ discount: '3', balance: '0', code: '', expiry_date: '', kind: 'discount'}}
          onSubmit={this.handleSubmit}
          validationSchema={Yup.object().shape({
            balance: Yup.number('только числа'),
            discount: Yup.number('только числа')
          })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleSubmit
        } = props;

        return (

          <form className='form' onSubmit={handleSubmit}>
            <h3>Новая карта</h3>
            <Message text={errors.msg} status={errors.status} />
            {!this.state.hasError && <div className="wrap">

            <RadioButtonsGroup
              id="kind"
              label="Тип карты"
              value={values.kind}
              error={errors.kind}
              touched={touched.kind}
              options={[{id: "cumulative", label: 'Накопительная'}, { id: "discount", label: 'Скидочная'}]}
            />

            {values.kind === 'discount' && <div className='form-group'>
              <label htmlFor="discount" className="label">Размер скидки</label>
              <Field
                id="discount"
                name="discount"
                type="text"
                value={values.discount}
                onChange={handleChange}
                className={
                  errors.discount && touched.discount ? 'field error' : 'field field--sm'
                }
              />
              {errors.discount
                && touched.discount && <InlineError text={errors.discount} />}
            </div>}

            {values.kind === 'cumulative' && <div className='form-group'>
              <label htmlFor="balance" className="label">Баланс на карте</label>
              <Field
                id="balance"
                name="balance"
                type="balance"
                value={values.balance}
                onChange={handleChange}
                className={
                  errors.balance && touched.balance ? 'field error' : 'field field--sm'
                }
              />
              {errors.balance
                && touched.balance && <InlineError text={errors.balance} />}
            </div>}

            <div className='form-group'>
              <label htmlFor="code" className="label">Номер-карты</label>
              <Field
                id="code"
                placeholder="Код"
                name="code"
                type="text"
                value={values.code}
                onChange={handleChange}
                className={
                  errors.code && touched.code ? 'field error' : 'field field--sm'
                }
              />
              {errors.code
                && touched.code && <InlineError text={errors.code} />}
              <small className='hint'>для подключения к системе ранее выущенных карт</small>
            </div>

            <div className='form-group'>
              <label htmlFor="expiry_date" className="label">Срок Действия карты</label>
              <Field
                id="expiry_date"
                name="expiry_date"
                type="date"
                value={values.expiry_date}
                onChange={handleChange}
                className={
                  errors.expiry_date && touched.expiry_date ? 'field error field--sm' : 'field field--sm'
                }
              />
              {errors.expiry_date
                && touched.expiry_date && <InlineError text={errors.expiry_date} />}
            </div>
            <Button type="submit" disabled={isSubmitting} text="Выпустить" /></div>}
          </form>
        );
      }}
    </Formik>
      </MainTemplate>
    );
    }
  }
}
export default CustomerForm;
