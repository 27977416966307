import React from 'react';
import MainTemplate from '../../templates/MainTemplate';
import Wrapper from '../../atoms/Wrapper';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Kind = styled.div`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  flex-direction: row;
  align-items: stretch;
  flex-wrap: wrap;
  
  div {
    align-self: stretch;
  }

  h1 {
    width: 100%;
  }

  @media (max-width: 880px) {
    flex-direction: column;
    
    h1 {
      padding: 0 10px;
    }
  }

  @media (min-width: 880px) {
    div:last-child {
      margin-left: 50px;
    }
  }
`;

const ProgramsPage = () => (
  <MainTemplate>
    <Kind>
      <h1>Выберите как вы будете поощрять своих клиентов</h1>
      <Wrapper>
        <Link to='/programs/discount'><h3>Скидочная</h3></Link>
        <p>либо с фиксированной, либо с накопительной скидкой</p>
        <p>Преимущества<br/>Проста и понятна, поэтому её легко организовать и контролировать.Клиенты очень любят скидки, в России особенно. </p>
        <p>Возможные недостатки<br/>Минус для торговли - скидки даются из чистой прибыли.
          Компании из одной сферы обычно предлагают примерно одинаковую скидку.
        </p>
        <details>
          <summary>Пример расчёта</summary>
          Допустим, вы продаете какой-то товар за 9 000 рублей. Ваша наценка составляет - 30% Скидка - 5% или 450 рублей.
          У вас есть себестоимость товара/услуги, которую вы никуда не денете, и соответственно скидку вы даете с вашей чистой прибыли.
          Таким образом 450 рублей вы на самом деле теряете не с оборота, а с вашей чистой прибыли, которая, например, составляет 2 700. Получается, на выходе с учётом скидки вы зарабатываете 2250.
          Итого размер скидки (если пересчитывать на чистые деньги) получается совсем не 5%, а целых 17,5%. А это уже, согласитесь, существенно. Конечно, это не так будет ощутимо, если ваша наценка будет 100-200 процентов, но а что если скидка будет больше?
        </details>
        Скидки широко применяются в больших ритейловых магазинах (Prisma, Лента) или в магазинах одежды (Henderson) и обуви (MANGO, Эконика).
      </Wrapper>
      <Wrapper>
        <Link to='/programs/cumulative'><h3>Накопительная (бонусная)</h3></Link>
        <p>Клиенты, будут получать на свой бонусный счет процент от своих покупок, потратить бонусы можно при совершении следующей покупки. Баллы должны логично конвертироваться в рубли, подарки или реальную скидку. Не стоит изобретать для баллов собственные названия и сложные схемы расчетов.</p>
        <p>Преимущества<br/>
          Не все клиенты приходят потратить бонусы, поэтому в итоге продаёте вообще без скидки. Чтобы накопить приличное число бонусов, покупатель должен покупать постоянно или на большие суммы, что выгодно бизнесу.</p>
        <p>Возможные недостатки<br/>
          Если покупка разовая или очень дорогая/редкая, то такая программа лояльности не подойдет. У клиента просто нет интереса в ней участвовать, так как больше он к вам не придёт.
        </p>
        <p>
          Примеры удачно внедренной бонусной программы лояльности — Do-do пицца, Перекресток, «Детский мир», Дочки-сыночки
        </p>
        <details>
          <summary>Пример расчёта</summary>
          1-я покупка - 9 000 рублей 2-я покупка (тоже) - 9 000 рублей Бонус с первой покупки (лежит на его карте) - 5% или 450 рублей.
          Таким образом, 2700 (первая покупка) + 2700 (вторая покупка) - 450 (списанные бонусы с первой покупки) = 4 950 (ваша чистая прибыль за минусом бонусной скидки). 
          Итого скидка с вашей прибыли составила 8,5% против 17,5% в первом случае.

          Со второй покупки клиент всё равно же получит ещё 450 рублей на счёт и получается, в результате мы выдадим те же самые 17,5%. И они будут правы.
        </details>

      </Wrapper>
    </Kind>
  </MainTemplate>
);

export default ProgramsPage;
