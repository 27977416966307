import React from 'react';
import { Field } from 'formik';
import InlineError from '../../atoms/InlineError';

const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  ...props
}) => {
  return (
    <label htmlFor={id} className="inline-label">
      <input
        name={name}
        id={id}
        type="radio"
        value={id}
        checked={id === value}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      /> {label}
    </label>
  );
};

// Radio group
const RadioButtonGroup = ({
  error,
  touched,
  label,
  children
}) => {
  return (
    <div className='form-group'>
      <label className='label'>{label}</label> {children}</div>
  );
};


const RadioButtonsGroup = (props) => {
  const { value, label, error, touched, options, id} = props;

  return (
    <RadioButtonGroup
      id={id}
      label={label}
      value={value}
      error={error}
      touched={touched}
    >
    {options.map((option) =>  <Field component={RadioButton} key={option.id} name={id} className="inline-label" id={option.id} label={option.label} />)}
    {error && touched.value  && <InlineError text={error} />}
  </RadioButtonGroup>
  );
};

export default RadioButtonsGroup;
