import React, { Component } from 'react';
import LoginForm from '../pages/LoginPage';

export default class LoginRouter extends Component {
  state = {
            loggedIn: false,
            user: (this.props.email ? this.props.email.user : ''),
            email: (this.props.email ? this.props.email.email : '')
          };

  onLogin = () => {
    this.setState({ loggedIn: true });
  }

  render() {

    const { loggedIn, email, user } = this.state;

    if (loggedIn) {
      window.location.assign("/");
    } else {
      return (<LoginForm onLogin={this.onLogin} email={email} user={user} />);
    }
  }
}
