import React from 'react';
import styled from 'styled-components';

const MainWrap = styled.div`
  position: relative;
  flex: 1;
  background: #fff;
  padding: 10px 20px 20px;
  box-shadow: 0 8px 18px 0 rgba(88, 88, 88, 0.1);
  max-width: 1200px;
  width: 100%;
  align-self: center;

  @media screen and (prefers-color-scheme: dark) {
    background-color: #2e2e2e;
    box-shadow: 0 7px 28px 0 rgba(0, 0, 0, 0.2);
    color: #fff;
  }

  @media (min-width: 1200px) {
    border-radius: 8px;
    padding: 20px 30px 30px;
  }

  @media (max-width: 500px) {
    padding: 10px;
  }
`;


const Wrapper = (props) => {
  const { children } = props;
  return (
    <MainWrap>
      {children}
    </MainWrap>
  );
};

export default Wrapper;