import React from 'react';
import MainTemplate from '../../templates/MainTemplate';
import Wrapper from '../../atoms/Wrapper';

const ProgramFormPage = () => (
  <MainTemplate>
    <Wrapper>
      Show
    </Wrapper>
  </MainTemplate>
);

export default ProgramFormPage;
