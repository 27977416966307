import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import AuthService from '../../../services/AuthService';
import SignUpTemplate from '../../templates/LoginTemplate';
import Button from '../../atoms/Button';
import Message from '../../atoms/Message';
import logoSrc from '../../../images/logo.svg';
import logoInvertedSrc from '../../../images/logo_inversed.svg';

const Form = styled.form`
  @media screen and (max-width: 699px) {
    padding: 0 20px;
    background: #fff;
    box-shadow: 0 0 18px 0 rgba(0,0,0,0.1);
    overflow: hidden;
    margin-top: 20px;
    max-width: 380px;
  }

  @media (min-width: 320px) and (max-width: 699px) {
    border-radius: 3px;
  }

  .success-message {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #f7f5f5;
    z-index: 100;
    display: flex;
    align-items: center;

    h2 {
      position: relative;

      &:before {
        position: absolute;
        left: 0;
        right: 0;
        top: -110px;
        content: '';
        display: inline-block;
        width: 100%;
        height: 50px;
        background-image: url(${logoSrc});
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;

        @media screen and (prefers-color-scheme: dark) {
          background-image: url(${logoInvertedSrc});
        }
      }
    }

    @media screen and (prefers-color-scheme: dark) {
      background: #272727;
    }
  }

  .message-wrap {
    max-width: 480px;
    margin: auto;
    box-shadow: 0 0 28px 0 rgba(0,0,0,0.1);
    padding: 20px 30px;
    border-radius: 8px;

    @media screen and (prefers-color-scheme: dark) {
      background-color: #2e2e2e;
      box-shadow: 0 7px 28px 0 rgba(0, 0, 0, 0.2);  
    }
  }
`;

const ErrorContainer = styled.div`
  margin-top: 5px;
  color: red;
`;

const Agreement = styled.div`
  max-width: 500px;
  margin-top: 10px;

  @media screen and (min-width: 700px) {
    padding-top: 10px;
  }
`;

const Login = styled.div`
  @media screen and (min-width: 700px) {
    position: absolute;
    right: 5%;
    top: 30px;
    font-size: 17px;
  }

  @media screen and (max-width: 699px) {
    text-align: center;
    padding: 10px 0;
    margin: 30px -20px 0;
    background: #f7f7f7;
    border-top: solid 1px #f0f0f0;
  }
`;


class SignUpForm extends Component {
  state = {
    emailSent: false,
  };

  handleSubmit = async (values, actions) => {
    try {
      const reponse = await AuthService.createAccount(values.fullname, values.email, values.password, values.password);
      if (reponse.status === 201) {
        this.setState({
          emailSent: true
        });
      }
    } catch (error) {
      if (error.message === "Network Error") {
        actions.setErrors({ status: 100 });
      } else {
        if (error.response.status === 500) {
          actions.setErrors({ status: 500 });
        } else {
          actions.setErrors({ auth: error.response.data.errors[0].detail });
        }
      }
    }
  };

  render() {
    const {
      emailSent
    } = this.state;
    return (
      <SignUpTemplate>
        <Formik
          initialValues={{ fullname: '', email: '', password: '', emailSent: false }}
          onSubmit={this.handleSubmit}
          validationSchema={Yup.object().shape({
            fullname: Yup.string().required('Как к вам обращаться?'),
            email: Yup.string()
              .email('Проверьте правильность адреса')
              .required('На email будет отправлена ссылка для доступа в личный кабинет'),
            password: Yup.string().required('Придумайте пароль').min(6, 'Придумайте пароль не менее 6-ти символов'),
          })}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleSubmit
            } = props;
            return (
              <Form onSubmit={handleSubmit}>
                <Message text={errors.auth} status={errors.status} />
                {emailSent && <div className="success-message">
                  <div className="message-wrap">
                    <h2>Подтвердите e-mail</h2>
                    <p>
                      Мы отправили письмо-подтверждение по&nbsp;адресу <strong>{values.email}</strong>. Пожалуйста, пройдите по&nbsp;ссылке в&nbsp;письме.
                      <br/><br/>
                      Настроить и&nbsp;запустить программы лояльности можно после подтверждения адреса электронной почты.</p>
                  </div></div>}
                {!errors.status && !emailSent && <div className="formWrap">
                  <h1>Регистрация</h1>
                <div className='form-group'>
                  <label className='label' htmlFor="fullname">Контактное лицо</label>
                  <Field
                    id="fullname"
                    name="fullname"
                    type="text"
                    placeholder="Имя и Фамилия"
                    value={values.fullname}
                    onChange={handleChange}
                    className={
                      errors.fullname && touched.fullname ? 'field error' : 'field'
                    }
                  />
                  {
                    errors.fullname
                    && touched.fullname && <ErrorContainer>{errors.fullname}</ErrorContainer>}
                </div>
                <div className='form-group'>
                  <label className='label' htmlFor="email">Email</label>
                  <Field
                    id="email"
                    name="email"
                    autoComplete="email"
                    type="email"
                    value={values.email}
                    onChange={handleChange}
                    className={
                      errors.email && touched.email ? 'field error' : 'field'
                    }
                  />
                  {errors.email
                    && touched.email && <ErrorContainer>{errors.email}</ErrorContainer>}
                </div>

                <div className='form-group'>
                  <label className='label' htmlFor="password">Пароль</label>
                  <Field
                    id="password"
                    autoComplete="new-password"
                    name="password"
                    type="password"
                    value={values.password}
                    placeholder="не менее 6-ти знаков"
                    onChange={handleChange}
                    className={
                      errors.password && touched.password ? 'field error' : 'field'
                    }
                  />
                  {
                    errors.password
                    && touched.password && <ErrorContainer>{errors.password}</ErrorContainer>}
                </div>
                <Button type="submit" disabled={isSubmitting} text="Продолжить" />
                <Agreement>Нажимая кнопку "Продолжить", вы принимаете условия <a href="/legal" target="_blank">обработки персональных данных</a> </Agreement>
                <Login>Уже зарегистрированы? <Link to="/login">Войти</Link></Login></div>}
              </Form>
            );
          }}
        </Formik>
      </SignUpTemplate>
    );
  }
}
export default SignUpForm;
