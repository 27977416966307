import React from 'react';
import Emoji from '../../atoms/Emoji';
import { withRouter } from 'react-router-dom'

const Row = (props) => {
  const { item, relationship, route, actions, options } = props;
  
  function showCell(key, value, options, index) {

    if(options[index] && options.length > 0) {
      return options[index][value];
    } else {
      if (key === 'points') {
        if (value > 0) {
          return '<span class="positive">' + value + '</span>';
        } else {
          return '<span class="negative">' + value + '</span>';
        }
      } else {
        if (key === 'created_at' || key === 'expiry_date' || key === 'birthdate') {
          let time = '';
          if(key === 'birthdate') {
            time = new Intl.DateTimeFormat('ru-RU', {month: 'long', day: 'numeric', year: 'numeric'}).format(new Date(value));
          } else {
            time = new Intl.DateTimeFormat('ru-RU', {month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric'}).format(new Date(value));
          }
          if (value == null) {
            return "—";
          } else {
            return time;
          }
        } else {
          if (value === 0 || value === '0.0') {
            return '';
          } else {
            return value;
          }
        }
      }
    }
  }

  function showRelation(item, relationship) {
    return <td>{item.relationships[relationship].data != null && <Emoji icon="✅" />}</td>
  }

  function changeRoutePath(e, link) {
    props.history.push(link);
  }

  if(actions) {
    return (
      <tr className='with-link' onClick={(e) => changeRoutePath(e, route)}>
        {Object.entries(item.attributes).map(([key, value], index)  => <td className={'cell-' + key} key={key} dangerouslySetInnerHTML={{__html: showCell(key, value, options, index)}}></td>)}
        {relationship && showRelation(item, relationship)}
      </tr>
    )
  } else {
    return (
      <tr>
        {Object.entries(item.attributes).map(([key, value], index)  => <td className={'cell-' + key} key={key} dangerouslySetInnerHTML={{__html: showCell(key, value, options, index)}}></td>)}
        {relationship && showRelation(item, relationship)}
      </tr>
    )
  }
};

export default withRouter(Row);
