import React from 'react';
import styled from 'styled-components';
import logoWhite from '../../../images/logo_white.svg';
import logo from '../../../images/logo.svg';

const LogoImg = styled.span`
  display: inline-block;
  width: 270px;
  height: 80px;
  background-image: url(${logoWhite});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

  @media screen and (max-width: 699px) {
    background-image: url(${logo});
    height: 50px;
  }
`;


const Logo = () => (
  <LogoImg></LogoImg>
);

export default Logo;
