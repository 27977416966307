import React from 'react';
import Emoji from '../../atoms/Emoji';
import MainTemplate from '../../templates/MainTemplate';
import List from '../../organisms/List';

const CardsPage = () => (
  <MainTemplate>
    <List className='collapsable' items="cards" relationships="customer" title="Карты" options={[{'discount':'Скидочная', 'cumulative':'Накопительная'}, '', '', '', '', '', '', '', '']} headers={["Тип карты", "Размер скидки", "Баллов на карте", "Номер карты", "Дата выпуска","Срок действия", "Выдана"]} actions={true}>
      <Emoji icon="🧧" size='xxx-large' label="Карт ещё нет" />
      <h2>Карт ещё нет</h2>
    </List>
  </MainTemplate>
);

export default CardsPage;
