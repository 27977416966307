import React from 'react';
import Emoji from '../../atoms/Emoji';
import MainTemplate from '../../templates/MainTemplate';
import List from '../../organisms/List';

const PosPage = () => (
  <MainTemplate>
    <List items="salespoints" title="Точки продаж" headers={["Название", "Дата добавления"]} actions={false}>
      <Emoji icon="🏬" size='xxx-large' label="Нет Точек продаж" />
      <h2>Нет Точек продаж</h2>
    </List>
  </MainTemplate>
);

export default PosPage;
