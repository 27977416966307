import api from './ApiService';
import decode from 'jwt-decode';

const loginUrl = '/tokens';
const signupUrl = '/users';

export default class AuthService {
  static TOKEN_KEY = 'authToken';

  static async authenticate(email, password) {
    const token = await AuthService.fetchToken(email, password);
    localStorage.setItem(AuthService.TOKEN_KEY, token);
  }

  static async createAccount(full_name, email, password, password_confirmation) {
    const response = await api.post(signupUrl, {
      data: {
        attributes: {
          full_name,
          email,
          password,
          password_confirmation,
        },
      },
    });
    return response;
  }

  static async createCompany(id, name, inn, type, website) {
    const token = AuthService.getToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await api.post("/users/" + id + "/company/", {
      data: {
        attributes: {
          name: name,
          inn: inn,
          type: type,
          website: website,
        },
      },
    }, config);

    return response;
  }

  static async fetchToken(email, password) {
    const response = await api.post(loginUrl, {
      data: {
        type: 'token',
        attributes: {
          email,
          password,
        },
      },
    });
    return response.data.data.attributes.value;
  }

  static logout() {
    localStorage.clear();
    window.location.assign("/login");
  }

  static getUserId() {
    const token = AuthService.getToken();
    return decode(token);
  }

  static async getUser() {
    const token = AuthService.getToken();
    const decoded = decode(token);

    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    return await api.get("/users/" + decoded.user_id, config);
  }

  static getToken() {
    return localStorage.getItem(AuthService.TOKEN_KEY);
  }

  static async confirmUser(confirmCode) {
    const response = api.get('/users/' + confirmCode + '/confirmation');
    return response;
  }
}