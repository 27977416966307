import React from 'react';
import styled from 'styled-components';

const Icon = styled.span`
  font-size: ${props => props.size};
`;

const Emoji = (props) => {
  const { icon, size, label } = props;
  return (
    <Icon role="img" aria-label={label} size={size}>{ icon }</Icon>
  );
};

export default Emoji;
