import React, { Component } from 'react';
import CardPreview from '../../molecules/Card';
import styled from 'styled-components';
import MainTemplate from '../../templates/MainTemplate';
import Wrapper from '../../atoms/Wrapper';
import DataService from '../../../services/DataListService';
import Emoji from '../../atoms/Emoji';
import { Link } from 'react-router-dom';

const Details = styled.div`
  border-top: solid 2px #f7f5f5;
  clear: both;
  margin: 20px -10px 0;
  padding: 15px 20px 10px;
  box-shadow: inset 0 10px 0 0 rgba(247, 245, 245, .5);

  p {
    margin: 0;
  }

  strong {
    font-weight: normal;
    font-size: 15px;
  }

  h3 {
    margin: 10px 0 0;
  }

  @media screen and (prefers-color-scheme: dark) {
    border-top: solid 2px #373737;
    box-shadow: inset 0 10px 0 0 rgba(0, 0, 0, .05);
  }

  @media (min-width: 650px) {
    margin: 20px -30px 0;
    padding: 13px 30px 10px;
  }
`;

class DetailView extends Component {
  constructor() {
    super();

    this.state = {
      Item: [],
      isLoading: true
    };

    this._isMounted = false;
  }

  componentDidMount(props) {
    this._isMounted = true;
    this._isMounted && this.updateItem(this.props.items);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateItem = async (route) => {
    const id = this.props.id;
    try {
      const data = await DataService.fetchList('cards/' + id + '?include[]=customer');

      if (this._isMounted) {
        this.setState({Item: data.data.data, isLoading: false});

        if (data.data.included[0].id) {
          this.setState({Customer: data.data.included[0].attributes, isLoading: false});
        }
      }
    } catch (error) {}
  };

  render() {
    const {
      Item, isLoading, Customer
    } = this.state;


    return (
      <MainTemplate>
        <Wrapper>
          <Link className='back-to-list' to={{ pathname: '/cards/' }}>&lsaquo; список карт</Link>
          {isLoading ? (<div className="loading">Загрузка...</div>) : ( <div className="card">
            <CardPreview id={Item.id} attributes={Item.attributes} size={200} />
            <br/>
            {Customer && <Details>
            <strong>Карта выдана клиенту</strong>
            <h3><Link to={{ pathname: '/customers/' + Item.relationships.customer.data.id }}>{Customer.first_name} {Customer.last_name}</Link></h3>
            {Customer.email && <p><Emoji icon="✉️" /> {Customer.email}</p>}
            {Customer.phone && <p><Emoji icon="📞" />{Customer.phone}</p>}
            {Customer.birthdate && <div><Emoji label='День рождения' icon='🎂' />{Intl.DateTimeFormat('ru-RU', {month: 'long', day: 'numeric', year: 'numeric'}).format(new Date(Customer.birthdate))}</div>}
            </Details>}
            {!isLoading && !Customer && (<Link to={{ pathname: '/customers/new/' + Item.id }} className='btn-hollow'>Выдать карту клиенту</Link>)}
          </div>)}
        </Wrapper>
      </MainTemplate>
    );
  }
}

export default DetailView;
