import React, { Component } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import AuthService from '../../../services/AuthService';
import DataService from '../../../services/DataListService';
import Message from '../../atoms/Message';
import InlineError from '../../atoms/InlineError';
import Button from '../../atoms/Button';
import MainTemplate from '../../templates/MainTemplate';

class CompanyForm extends Component {
  constructor() {
    super();

    this.state = {
      Item: [],
      isLoading: true
    };

    this._isMounted = false;
  }

  componentDidMount(props) {
    this._isMounted = true;
    this._isMounted && this.updateItem(this.props.items);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }


  updateItem = async (route) => {
    const id = AuthService.getUserId();
    try {
      const data = await DataService.fetchList('users/' + id.user_id + '?include[]=company');

      if (this._isMounted) {
        this.setState({Company: data.data.included[0].attributes, isLoading: false});
      }
    } catch (error) {}
  };

  
  handleSubmit = async (values, actions) => {
    try {
    } catch (error) {
      if (error.message === "Network Error") {
        actions.setErrors({ status: 100 });
      } else {
        if (error.response) {
          if (error.response.status === 500) {
            actions.setErrors({ status: 500 });
          } else {
            actions.setErrors({ auth: error.response.data.errors[0].detail });
          }
        }
      }
      actions.setSubmitting(false);
    }
  };

  render() {
    const {
      Company, isLoading
    } = this.state;
    return (
      <MainTemplate>
      {!isLoading && 
      <Formik
      initialValues={{ name: Company.name, inn: Company.inn, type: '', website: Company.website }}
      onSubmit={this.handleSubmit}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Чтобы ваши клиенты узнали вас, можно изменить в любое время'),
        inn: Yup.number().required('Обязательно').min(10, 'Проверьте количество цифр')
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleSubmit
        } = props;
        
        return (
          <form className='form' onSubmit={handleSubmit}>
            <Message text={errors.auth} status={errors.status} />
            {!this.state.hasError && <div className="wrap">
            <br/>
            <div className='form-group select'>
              <label htmlFor="type" className="label">Вид деятельности</label>
              <Field
                component="select"
                id="type"
                name="type"
                value={values.type}
                onChange={handleChange}
                className='field'
              >
                <option value="horeca">Кафе, рестораны, бары, пекарни, кофейни (HoReCa)</option>
                <option value="auto">Авторемонтная мастерская, Автомойки, АЗС</option>
                <option value="hospitality">Гостиница, хостел, отель</option>
                <option value="ecommerce">Интернет-магазин</option>
                <option value="beauty">Салоны красоты, косметология</option>
                <option value="b2b">Партнерская сеть (b2b)</option>
                <option value="retail">Розничная торговля (сетевая)</option>
                <option value="entertainment">Кинотеатры, Детские центры</option>
                <option value="hr">Мотивация сотрудников</option>
                <option value="other">Другой</option>
              </Field>
            </div>

            <div className='form-group'>
              <label htmlFor="name" className="label">Название бизнеса</label>
              <Field
                id="name"
                name="name"
                type="text"
                autoFocus
                value={values.name}
                onChange={handleChange}
                placeholder="Как он представлен клиентам"
                className={
                  errors.name && touched.name ? 'field error' : 'field'
                }
              />
              {errors.name
                && touched.name && <InlineError text={errors.name} />}
              {!errors.name &&
                <span className='hint'>Как его будут видеть ваши клиенты</span>}
            </div>

            <div className='form-group'>
              <label htmlFor="inn" className="label">ИНН</label>
              <Field
                id="inn"
                name="inn"
                type="number"
                value={values.inn}
                disabled="disabled"
                className='field'
              />
            </div>

            <div className='form-group'>
              <label htmlFor="website" className="label">Сайт</label>
              <Field
                id="website"
                name="website"
                type="url"
                value={values.website}
                onChange={handleChange}
                placeholder="необязательно"
                className='field'
              />
            </div>

            <div className='form-group'>
              <label htmlFor="address" className="label">Контакты</label>
              <Field component="textarea"
                id="address"
                name="address"
                value={values.address}
                onChange={handleChange}
                placeholder="необязательно"
                className='field'
              />
            </div>
            <Button type="submit" disabled={isSubmitting} text="Сохранить" /></div>}
          </form>
        );
      }}
    </Formik>}
    </MainTemplate>
    );
  }
}
export default CompanyForm;
