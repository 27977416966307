import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

import LoginRouter from '../LoginRouter';
import ConfirmRouter from '../ConfirmRouter';
import SignUpPage from '../pages/SignupPage';
import LegalPage from '../pages/legalPage';
import ForgotPage from '../pages/ForgotPage';
import NotFound from '../404';

const Index = (props) => <LoginRouter email={props.location.state} />;
const SignUp = () => <SignUpPage />;
const Legal = () => <LegalPage />;
const NotFoundPage = () => <NotFound />;
const Confirm = (props) => <ConfirmRouter id={props.match.params.id} />;

const AppRouter = () => (
  <Router>
    <Switch>
      <Route path="/" exact component={SignUp} />
      <Route path="/signup" component={SignUp} />
      <Route path="/login" component={Index} />

      <Route path="/forgot" component={ForgotPage} />
      <Route path="/legal" component={Legal} />
      <Route path="/confirm/:id" component={Confirm} />
      <Route path="*" component={NotFoundPage} />
    </Switch>
  </Router>
);

export default AppRouter;
