import React from 'react';
import styled from 'styled-components';
import Logo from '../../atoms/Logo';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 20px 15px;
  z-index: 10;
`;

const Wrap = styled.div`
  padding: 20px 15px;
  max-width: 800px;
  line-height: 25px;
`;

const ConfirmTemplate = (props) => {
  const { children } = props;

  return (
    <Wrapper>
      <Header>
        <Logo />
      </Header>
      <Wrap>
        {children}
      </Wrap>
    </Wrapper>
  );
};

export default ConfirmTemplate;
