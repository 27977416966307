import React from 'react';
import styled from 'styled-components';

const Indicator = styled.div`
  background-color: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100%;
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Spinner = () => {
  return (
    <Indicator>Загрузка...</Indicator>
  );
};

export default Spinner;
