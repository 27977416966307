import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import AuthService from '../../../services/AuthService';
import LoginTemplate from '../../templates/LoginTemplate';
import Message from '../../atoms/Message';
import icon from '../../../images/success.svg';
import Button from '../../atoms/Button';

const Strong = styled.strong`
  color: #1ec06a;
  font-size: 16px;
  padding: 10px 15px 10px 35px;
  margin-bottom: 10px;
  display: block;
  margin-top: -10px;
  background: url(${icon}) center left 10px no-repeat #E8F8F0;

  @media screen and (prefers-color-scheme: dark) {
    background: #1ec06a;
    color: #fff;
    padding-left: 15px;
  }
`;

const SignUpLink = styled.div`
  @media screen and (min-width: 700px) {
    position: absolute;
    right: 5%;
    top: 30px;

    .btn {
      font-size: 18px;
      border: solid 1px #3f74ff;
      padding: 10px 30px;
      border-radius: 5px;
      line-height: 32px;
      
      &:hover {
        background: #3f74ff;
        color: #fff;
      }
    }
  }

  @media screen and (max-width: 699px) {
    text-align: center;
    padding: 10px 0;
    margin: 30px -20px 0;
    background: #f7f7f7;
    border-top: solid 1px #f0f0f0;
  }
`;

const ErrorContainer = styled.div`
  margin-top: 5px;
  color: red;
`;

const Form = styled.form`
  @media screen and (max-width: 699px) {
    padding: 0 20px;
    background: #fff;
    box-shadow: 0 0 18px 0 rgba(0,0,0,0.1);
    overflow: hidden;
    margin-top: 20px;
    max-width: 380px;
  }

  @media (min-width: 320px) and (max-width: 699px) {
    border-radius: 3px;
  }
`;

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      email: props.email,
      isLoading: true,
      errors: null
    };
  }

  handleSubmit = async (values, actions) => {
    try {
      await AuthService.authenticate(values.email, values.password);
      const { onLogin } = this.props;
      onLogin();
    } catch (error) {
      actions.setSubmitting(false);

      if (error.message === "Network Error") {
        actions.setErrors({ status: 100 });
      } else {
        if (error.response.status === 500) {
          actions.setErrors({ status: 500 });
        } else {
          actions.setErrors({ auth: JSON.parse(error.response.request.response).errors[0].detail });
        }
      }
    }
  };
  
  render() {
    return (
      <LoginTemplate>
        { this.props.user && <Strong>Ваш Email подтверждён!</Strong> }
        <h2>Вход в Личный кабинет</h2>
        <Formik
          initialValues={{ email: this.props.email, password: '' }}
          onSubmit={this.handleSubmit}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email('Проверьте правильность адреса')
              .required('Без email не получится'),
            password: Yup.string().required('Без пароля не получится'),
          })}
        >
          {(props) => {
            const {
              values,
              errors,
              touched,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit
            } = props;

            return (
              <Form onSubmit={handleSubmit}>
                <Message text={errors.auth} status={errors.status} />
                {!errors.status && <div className='wrap'>
                <div className='form-group'>
                  <label htmlFor="email" className="label">Email</label>
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="field"
                  />
                  { touched.email && errors.email && !errors.password && <ErrorContainer>Проверьте правильность адреса</ErrorContainer>}
                </div>

                <div className='form-group'>
                  <label htmlFor="password" className="label">Пароль</label>
                  <Field
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="field"
                  />
                </div>

                <Button type="submit" disabled={isSubmitting} text="Войти" />
                <Link to="/forgot">Забыли пароль?</Link>
                </div>}
                <SignUpLink><Link to="/signup" className='btn'>Регистрация</Link></SignUpLink>
              </Form>
            );
          }}
        </Formik>

      </LoginTemplate>
    );
  }
}
export default LoginForm;
