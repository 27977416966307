import React, { Component } from "react";
import authService from './services/AuthService';
import Spinner from './components/atoms/Spinner';
import Message from './components/atoms/Message';
import AuthenticatedApp from './components/AppRouter';

import UnauthenticatedApp from './components/AppRouter/public';

import { UserContext } from './context/user';

class App extends Component {
  constructor(props){
    super(props);

    this.state = {
      loading: true,
      currentUser: '',
      loggedIn: false,
      company: '',
      errors: '',
    };

    if (authService.getToken()) {
      authService.getUser().then(response => response)
      .then(data => { this.setState({currentUser: data.data.data, loggedIn: true, loading: false }); })
      .catch((error) => {
        if (error.message === "Network Error") {
          this.setState({errors: {status: 100}, loggedIn: true, loading: false });
        } else {
          if (error.response.status === 401 || error.response.status === 403) {
            authService.logout();
            window.location.reload();
          }

          // TODO: Need to resolve on server side
          if (error.response.status === 500) {
            if(error.response.data.exception === "#<NoMethodError: undefined method `users' for nil:NilClass>") {
              const id = authService.getUserId();
              this.setState({currentUser: {id: id, type: "user", attributes: {}, relationships: {company: {data: null}}}, loggedIn: true, loading: false });
            } else {
              if(error.response.data.exception === "#<JWT::ExpiredSignature: Signature has expired>") {
                authService.logout();
                window.location.assign('/login');
              } else {
                this.setState({errors: {status: 500}, loggedIn: true, loading: false });
              }
            }
          }
        }
      });  
    };
  }

  render() {
    if(this.state.errors) {
      return (<Message status={this.state.errors.status} />);
    } else {
      return (
        <section className="content">
          { !authService.getToken()
            ? <UnauthenticatedApp />
            : (this.state.loggedIn && !this.state.loading
              ? <UserContext.Provider value={this.state.currentUser}><AuthenticatedApp /></UserContext.Provider>
              : <div><Spinner /><UserContext.Provider value={this.state.currentUser}><AuthenticatedApp /></UserContext.Provider></div>
            )}
        </section>
      );
    }
  }
}

export default App;
