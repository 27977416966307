import React, { Component } from 'react';
import DataService from '../../../services/DataListService';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Row from '../../molecules/Row';
import Wrapper from '../../atoms/Wrapper';

const Wrap = styled.div `
  max-width: 1200px;
  width: 100%;
  align-self: center;
  flex: 1;
  margin: auto;

  @media (max-width: 1200px) {
    &.default {
      padding-left: 30px;
      padding-right: 30px;
      max-width: 100%;
    }
  }

  @media (max-width: 1000px) {
    &.default {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`;

const Table = styled.table`
  border-collapse: collapse;
  empty-cells: hide;

  tr {
    vertical-align: top;
  }

  th {
    text-align: left;
    padding: 5px 10px;
    border-bottom: solid 1px #000;
  }

  td {
    padding: 5px 10px;
  }

  @media screen and (prefers-color-scheme: dark) {
    th {
      border-bottom: solid 1px #697075;
    }
  }

  @media (max-width: 650px) {
    td:empty {
      display: none;
    }

    td {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
`;

const Header = styled.div`
  h2 {
    display: inline-block;
    margin-right: 10px;
  }

  .import {
    float: right;
  }

  @media (max-width: 800px) {
    h2 {
      margin-bottom: 0;
      font-size: 18px;
    }
  }
  @media (max-width: 500px) {
    h2 {
      margin-top: 0;
    }
  }
`;

class List extends Component {
  constructor() {
    super();

    this.state = {
      List: [],
      isLoading: true,
      errors: null
    };
  
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.updateList(this.props.items);
  }

  getRoute = (id) => {
    return "/" + this.props.items + "/" + id;
  }

  updateList = async (route) => {
    try {
      const data = await DataService.fetchList(route);
      if(this._isMounted) {
        this.setState({List: data.data.data, isLoading: false})
      }
    } catch (error) {
      // const errorMessage = JSON.parse(error.response.request.response).errors[0].detail;
    }
  };
  
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      List, isLoading
    } = this.state;
    const { headers, title, children, actions, items, relationships, className, options } = this.props;

    if(List.length > 0) {
      return (
        <Wrap className={className ? className + '-view' : 'default'}>
          <Header>
            <h2>{ title }</h2>
            {actions && <span><Link to={'/' + items + '/new'}>Добавить</Link>
            <Link to={items + '/import'} className='import'>Импорт</Link></span>}
          </Header>
          <div className={className}>
            <Table>
              <thead>
                <tr>
                  {headers.map(item => <th key={item}>{item}</th>)}
                </tr>
              </thead>
              <tbody>
                {isLoading ? (<tr><td>Загрузка...</td></tr>) : (
                List.map(item => <Row item={item} options={options} relationship={relationships} route={this.getRoute(item.id)} key={item.id} actions={actions} />))}
              </tbody>
            </Table>
          </div>
        </Wrap>
      );
    } else {
      return(
        <Wrapper>
          {isLoading ? (<div>Загрузка...</div>) : (
            <div className='no-data'>
              {children}
              <br/>
              {actions && <span><Link to={items + '/new'} className='btn'>Добавить</Link> <Link to={items + '/import'} className='btn-hollow'>Импорт</Link></span> }
            </div>)}
        </Wrapper>
      )
    }
  }
}

export default List;
