import React from 'react';
import styled from 'styled-components';
import logo from '../../../images/logo.svg';
import logoInversed from '../../../images/logo_inversed.svg';

const LogoImg = styled.span`
  display: inline-block;
  width: 206px;
  height: 50px;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position: top left;
  background-size: auto 100%;

  @media (max-width: 1000px) {
    width: 50px;
  }

  @media (max-width: 1220px) {
    width: 36px;
    height: 36px;
  }

  @media (prefers-color-scheme: dark) {
    background-image: url(${logoInversed});
  }
`;


const Logo = () => (
  <LogoImg></LogoImg>
);

export default Logo;
