import React from 'react';
import styled from 'styled-components';

// Icons
import iconB2B from '../../../images/types/b2b.svg';
import iconCafe from '../../../images/types/cafe.svg';
import iconCarService from '../../../images/types/car_service.svg';
import iconCarWash from '../../../images/types/car_wash.svg';
import iconCoffee from '../../../images/types/coffee.svg';
import iconEcommerce from '../../../images/types/ecommerce.svg';
import iconHR from '../../../images/types/hr.svg';
import iconRetail from '../../../images/types/retail.svg';

const BusinessType = styled.div`
  align-self: center;
  text-align: center;
`;


const List = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 20px 0;

  li {
    margin: 0 1% 20px;
    width: 280px;
    height: 280px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.1);

    @media screen and (prefers-color-scheme: dark) {
      background-color: #2e2e2e;
      box-shadow: 0 7px 28px 0 rgba(0, 0, 0, 0.2);
      color: #fff;

      img {
        filter: invert(100%);
      }

      path {
        fill: #fff;
      }
    }
  }

  li:hover {
    box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  img {
    margin-top: 30px;
  }

  p {
    margin-top: 10px;
  }
`;

class BusinessTypes extends React.Component {
  render() {
    var onSetType  =   this.props.onSetType;
    
    return (
      <BusinessType>
        <h1>Расскажите о своём бизнесе</h1>
        мы предложим вам готовые решения программ лояльности
        <List>
          <li onClick={() => onSetType("horeca") }>
            <img src={iconCafe} alt="Кафе" />
            <img src={iconCoffee} alt="кофейни" />
            <p>Кафе, рестораны, бары, пекарни, кофейни (HoReCa)</p>
          </li>
          <li onClick={() => onSetType("auto") }>
            <img src={iconCarService} alt="Авторемонтная мастерская" />
            <img src={iconCarWash} alt="Автомойки" />
            <p>Авторемонтная мастерская, Автомойки, АЗС</p>
          </li>
          <li onClick={() => onSetType("beauty") }>
            <img src={iconCarService} alt="Авторемонтная мастерская" />
            <p>Салоны красоты, косметология</p>
          </li>
          <li onClick={() => onSetType("entertainment") }>
            <img src={iconCarService} alt="Авторемонтная мастерская" />
            <p>Кинотеатры, Детские центры</p>
          </li>
          <li onClick={() => onSetType("ecommerce") }>
            <img src={iconEcommerce} alt="Интернет-магазин" />
            <p>Интернет-магазин</p>
          </li>
          <li onClick={() => onSetType("hospitality") }>
            <img src={iconEcommerce} alt="Гостиница, хостел, отель" />
            <p>Гостиница, хостел, отель</p>
          </li>
          <li onClick={() => onSetType("b2b") }>
            <img src={iconB2B} alt="Партнерская сеть (b2b)" />
            <p>Партнерская сеть (b2b)</p>
          </li>
          <li onClick={() => onSetType("retail") }>
            <img src={iconRetail} alt="Розничные магазины" />
            <p>Розничная торговля (сетевая)</p>
          </li>
          <li onClick={() => onSetType("other") }>
            <img src={iconHR} alt="Другой" />
            <p>Другой</p>
          </li>
          <li onClick={() => onSetType("exist") }>
            <p>У меня уже есть программа лояльности с картами</p>
          </li>
        </List>
      </BusinessType>
    )}}

export default BusinessTypes;
