import React, { Component } from 'react';

import DataService from '../../../services/DataListService';
import MainTemplate from '../../templates/MainTemplate';
import Wrapper from '../../atoms/Wrapper';
import Emoji from '../../atoms/Emoji';
import List from '../../organisms/List/static';
import CardPreview from '../../molecules/Card';
import { Link } from 'react-router-dom';

class DetailView extends Component {
  constructor() {
    super();

    this.state = {
      Item: [],
      Card: [],
      isLoading: true
    };

    this._isMounted = false;
  }

  componentDidMount(props) {
    this._isMounted = true;
    this._isMounted && this.updateList(this.props.items);
  }

  updateList = async (route) => {
    const id = this.props.id;
    try {
      // const data = await DataService.fetchList('customers/' + id);
      const data = await DataService.fetchList('customers/' + id + '?include[]=cards');
      if (this._isMounted) {
        this.setState({Item: data.data.data});
        if (data.data.data.relationships.cards.data.length > 0) {
          this.setState({Card: data.data.included[0]});
        }
        this.setState({isLoading: false});
      }

    } catch (error) {
      // const errorMessage = JSON.parse(error.response.request.response).errors[0].detail;
    }
  };
  
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const TransactionsData = {"data": [{"id": "23", "type": "customer", "attributes": {"id": "292", "confirmation": "ПР-623-139461", "description": "Списание бонусов на любые покупки", "points": "-500", "balance": "350", "total": "500", "cashier": "cash@test.com", "point": "касса №1", "created_at": "2020-01-06T19:24:14.234Z"}, }, {"id": "34", "type": "customer", "attributes": {"id": "740", "confirmation": "", "description": "Бонусы нв День рождения", "points": "+500", "balance": "850", "total": "", "cashier": "", "point": "", "created_at": "2020-01-08T07:41:05.452Z"}, }, {"id": "38", "type": "customer", "attributes": {"id": "935", "confirmation": "", "description": "Бонусы за регистрацию", "points": "+300", "balance": "350", "total": "", "cashier": "", "point": "", "created_at": "2020-01-08T10:59:15.263Z"}, }, {"id": "39", "type": "customer", "attributes": {"id": "255", "confirmation": "ЗК-57010-903343", "description": "Кэшбэк бонусы за покупки", "points": "+50", "balance": "50", "total": "1000", "cashier": "cash@test.com", "point": "касса №1", "created_at": "2020-01-08T11:00:16.345Z"}, } ] }

    const {
      isLoading, Item, Card
    } = this.state;

    return (
      <MainTemplate>
        <Wrapper>
          <Link className='back-to-list' to={{ pathname: '/customers/' }}>&lsaquo; список клиентов</Link>
          {isLoading ? (<div className="loading">Загрузка...</div>) : ( <div className="customer">
              <div className='info-container'>
                <div className="customer-info">
                  <h2>{Item.attributes.first_name} {Item.attributes.last_name}</h2>
                  {Item.attributes.birthdate && <div>День рождения <Emoji label='День рождения' icon='🎂' />{Intl.DateTimeFormat('ru-RU', {month: 'long', day: 'numeric', year: 'numeric'}).format(new Date(Item.attributes.birthdate))}</div>}
                  <div className='fields'>
                    {Item.attributes.email && <div className='item'><label>Email</label> <a href={'mailto:' + Item.attributes.email}>{Item.attributes.email}</a></div>}
                    {Item.attributes.phone && <div className='item'><label>Телефон</label> {Item.attributes.phone}</div>}
                    {Item.attributes.telegram && <div className='item'><label>Телеграм</label> <a href={'https://t.me/' + Item.attributes.telegram}>{Item.attributes.telegram}</a></div>}
                    Зарегистрирован&nbsp;<time>{Intl.DateTimeFormat('ru-RU', {month: 'long', day: 'numeric', year: 'numeric'}).format(new Date(Item.attributes.created_at))}</time> (источник: <Link to='/pos'>интернет-магазин</Link>)
                  </div>
                </div>
                {Item.relationships.cards.data.length > 0 && <CardPreview kind='card-preview' id={Card.id} attributes={Card.attributes} size={150} />}
              </div>

              {Item.relationships.cards.data.length > 0  && <List className='scrollable' options={[]} data={TransactionsData} items="transactions" title="Операции по карте" headers={["№", "Чек", "Операция", "Баллы", "Остатки после операции", "Сумма по чеку", "Оператор", "Точка продаж", "Дата"]} actions={false}>
                <h2>За выбранный период нет ни одной оплаты</h2>
                <p>Для проведения оплат используйте интерфейс кассира</p>
              </List>}

          </div>)}
        </Wrapper>
      </MainTemplate>
    );
  }
}

export default DetailView;
