import React, { Component } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import AuthService from '../../../services/AuthService';
import Message from '../../atoms/Message';
import Button from '../../atoms/Button';
import BusinessTypes from '../../organisms/Types';
import InlineError from '../../atoms/InlineError';

const Wrap = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
`;

class CompanyForm extends Component {
  
  constructor(props) {
    super(props)

    this.handler = this.onSetType.bind(this)

    this.state = {
      hasError: false,
      businessType: ''
    };
  }
  
  handleSubmit = async (values, actions) => {
    try {
      const reponse = await AuthService.createCompany(this.props.user.id, values.name, values.inn, values.type, values.website);
      if (reponse.status === 201) {
        window.location.assign("/setup");
      }
    } catch (error) {
      if (error.message === "Network Error") {
        actions.setErrors({ status: 100 });
      } else {
        if (error.response) {
          if (error.response.status === 500) {
            actions.setErrors({ status: 500 });
          } else {
            actions.setErrors({ auth: error.response.data.errors[0].detail });
          }
        }
      }
      actions.setSubmitting(false);
    }
  };

  onSetType = (value) => {
    this.setState({
      businessType: value
    })
  }

  render() {
    const { businessType } = this.state;
 
    return (
      <Wrap>
        {!businessType && <BusinessTypes onSetType={this.onSetType}></BusinessTypes>}
        {businessType && <Formik
      initialValues={{ name: '', inn: '', type: businessType, website: '', address: '' }}
      onSubmit={this.handleSubmit}
      validationSchema={Yup.object().shape({
        name: Yup.string().required('Используется для общения с клиентами, можно изменить в любое время'),
        inn: Yup.string().min(10, 'Проверьте количество цифр').max(12, 'Проверьте количество цифр').required('Необходимо для документов')
      })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleSubmit
        } = props;
        
        return (
          <form className='form' onSubmit={handleSubmit}>
            <h2>Расскажите о своём бизнесе</h2>
            <Message text={errors.auth} status={errors.status} />
            {!this.state.hasError && <div className="wrap">
            <br/>
            <div className='form-group select'>
              <label htmlFor="type" className="label">
                Вид деятельности
              </label>
              <Field
                id="type"
                name="type"
                component="select"
                value={values.type}
                onChange={handleChange}
                className='field'
              >
                <option value="horeca">Кафе, рестораны, бары, пекарни, кофейни (HoReCa)</option>
                <option value="auto">Авторемонтная мастерская, Автомойки, АЗС</option>
                <option value="hospitality">Гостиница, хостел, отель</option>
                <option value="ecommerce">Интернет-магазин</option>
                <option value="beauty">Салоны красоты, косметология</option>
                <option value="b2b">Партнерская сеть (b2b)</option>
                <option value="retail">Розничная торговля (сетевая)</option>
                <option value="entertainment">Кинотеатры, Детские центры</option>
                <option value="hr">Мотивация сотрудников</option>
                <option value="other">Другой</option>
              </Field>
            </div>

            <div className='form-group'>
              <label htmlFor="name" className="label">
                Название бизнеса
              </label>
              <Field
                id="name"
                name="name"
                type="text"
                value={values.name}
                onChange={handleChange}
                placeholder="Как он представлен клиентам"
                className={
                  errors.name && touched.name ? 'field error' : 'field'
                }
              />
              {errors.name
                && touched.name && <InlineError text={errors.name} />}
            </div>

            <div className='form-group'>
              <label htmlFor="inn" className="label">
                ИНН
              </label>
              <Field
                id="inn"
                name="inn"
                type="number"
                value={values.inn}
                onChange={handleChange}
                className={
                  errors.inn && touched.inn ? 'field error' : 'field'
                }
              />
              {
                errors.inn
                && touched.inn && <InlineError text={errors.inn} />}
            </div>

            <div className='form-group'>
              <label htmlFor="website" className="label">
                Сайт
              </label>
              <Field
                id="website"
                name="website"
                type="url"
                value={values.website}
                onChange={handleChange}
                placeholder="необязательно"
                className='field'
              />
            </div>

            <div className='form-group'>
              <label htmlFor="address" className="label">Контакты</label>
              <Field component="textarea"
                id="address"
                name="address"
                value={values.address}
                onChange={handleChange}
                placeholder="необязательно"
                className='field'
              />
            </div>

            <Button type="submit" disabled={isSubmitting} text="Продолжить" /></div>}
          </form>
        );
      }}
    </Formik>}
    </Wrap>
    );
    }
}
export default CompanyForm;
