import React, { Component } from 'react';
import { Formik, Field } from 'formik';
import { Redirect } from 'react-router';
import * as Yup from 'yup';
import Message from '../../atoms/Message';
import InlineError from '../../atoms/InlineError';

import Button from '../../atoms/Button';
import MainTemplate from '../../templates/MainTemplate';

class ProgramForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      success: false
    };
  }
  
  handleSubmit = async (values, actions) => {
    try {
      this.setState({ success: true });
    } catch (error) {
      actions.setSubmitting(false);
      if (error.message === "Network Error") {
        actions.setErrors({ status: 100 });
      } else {
        if (error.response) {
          if (error.response.status === 500) {
            actions.setErrors({ status: 500 });
          } else {
            actions.setErrors({ msg: error.response.data.errors[0].detail });
          }
        }
      }
    }
  };

  render() {
    const {
      success
    } = this.state;

    if (success) {
      return (<Redirect to={ "/programs"} />)
    } else {
    return (
      <MainTemplate>
        <Formik
          initialValues={{ discount: '3', balance: '0', code: '', expiry_date: '', kind: 'cumulative', birthdayDiscountDays: 7}}
          onSubmit={this.handleSubmit}
          validationSchema={Yup.object().shape({
            balance: Yup.number('только числа'),
            discount: Yup.number('только числа')
          })}
    >
      {(props) => {
        const {
          values,
          touched,
          errors,
          isSubmitting,
          handleChange,
          handleSubmit
        } = props;

        return (

          <form className='form' onSubmit={handleSubmit}>
            <h2>Настройки бонусной программы лояльности</h2>
            <Message text={errors.msg} status={errors.status} />
            {!this.state.hasError && <div className="wrap">

            <div className='form-group'>
              <label htmlFor="balance" className="label">Бонус за регистрацию</label>
              <Field
                id="balance"
                name="balance"
                type="balance"
                value={values.balance}
                onChange={handleChange}
                className={
                  errors.balance && touched.balance ? 'field error' : 'field field--sm'
                }
              />
              {errors.balance
                && touched.balance && <InlineError text={errors.balance} />}
            </div>

            <div className='form-group'>
              <label htmlFor="percentage" className="label">Размер бонусов</label>
              <Field
                id="percentage"
                name="percentage"
                type="percentage"
                placeholder="%"
                value={values.percentage}
                onChange={handleChange}
                className={
                  errors.percentage && touched.percentage ? 'field error' : 'field field--sm'
                }
              />
              <small className='hint'>бонус за транзакцию, кешбек</small>
              {errors.balance
                && touched.balance && <InlineError text={errors.balance} />}
            </div>

            <div className='form-group'>
              <label htmlFor="payment" className="label">Максимальный % списания</label>
              <Field
                id="payment"
                name="payment"
                type="payment"
                placeholder="%"
                value={values.payment}
                onChange={handleChange}
                className={
                  errors.payment && touched.payment ? 'field error' : 'field field--sm'
                }
              />
              <small className='hint'>максимально возможный % оплаты чека бонусами</small>
              {errors.balance
                && touched.balance && <InlineError text={errors.balance} />}
            </div>
            <div className='form-group'>
              <label htmlFor="expiry_date" className="label">Период активности</label>
              <Field
                id="start_date"
                name="start_date"
                type="date"
                value={values.start_date}
                onChange={handleChange}
                className={
                  errors.start_date && touched.start_date ? 'field error field--sm' : 'field field--sm'
                }
              />
              <Field
                id="expiry_date"
                name="expiry_date"
                type="date"
                value={values.expiry_date}
                onChange={handleChange}
                className={
                  errors.expiry_date && touched.expiry_date ? 'field error field--sm' : 'field field--sm'
                }
              />
              {errors.expiry_date
                && touched.expiry_date && <InlineError text={errors.expiry_date} />}
            </div>

            <div className='form-group'>
              <Field
                id="birthday"
                name="birthday"
                type="checkbox"
                value={values.birthday}
                onChange={handleChange}
              />
              <label htmlFor="birthday" className="label">Бонусы на день рождения</label>
            </div>
            {values.birthday &&
            <div className='birthday'>
              <div className='form-group'>
                <label htmlFor="birthdayDiscount" className="label">Кол-во баллов</label>
                <Field
                  id="birthdayDiscount"
                  name="birthdayDiscount"
                  type="number"
                  size="3"
                  max="100"
                  value={values.birthdayDiscount}
                  onChange={handleChange}
                  className={
                    errors.birthdayDiscount && touched.birthdayDiscount ? 'field error' : 'field field--xs'
                  }
                />
              </div>
              <div className='form-group'>
                <label htmlFor="birthdayDiscountDays" className="label">Активность баллов</label>
                <Field
                  id="birthdayDiscountDays"
                  name="birthdayDiscountDays"
                  type="number"
                  size="3"
                  value={values.birthdayDiscountDays}
                  onChange={handleChange}
                  className={
                    errors.birthdayDiscountDays && touched.birthdayDiscountDays ? 'field error' : 'field field--xs'
                  }
                />
                <label className="label">дней</label>
                <small className='hint'>В день рождения и {values.birthdayDiscountDays} дня после дня рождения будет доступна скидка {values.birthdayDiscount}%.</small>
              </div>
            </div>}
            <Button type="submit" disabled={isSubmitting} text="Сохранить" /></div>}
          </form>
        );
      }}
    </Formik>
      </MainTemplate>
    );
    }
  }
}
export default ProgramForm;
