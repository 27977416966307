import React from 'react';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';

class DateRange extends React.Component {
  state = {
    startDate: new Date(),
    endDate: null
  };

  handleStartDateChange = date => {
    this.setState({
      startDate: date
    });
  };

  handleEndDateChange = date => {
    this.setState({
      endDate: date
    });
  }

  render() {
  return (
    <span>
      <DatePicker
        className={'field field--sm'}
        selected={this.state.startDate}
        onChange={this.handleStartDateChange}
      />
      <DatePicker
        className={'field field--sm'}
        selected={this.state.endDate}
        onChange={this.handleEndDateChange}
      />
    </span>
  )}
};

export default DateRange;