import React, { Component } from 'react';
import { Redirect } from 'react-router';
import AuthService from '../../services/AuthService';

export default class ConfirmRouter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      user: 0,
      email: ''
    };
  
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;

    if(this.props.id) {
      this._isMounted && this.confirmUser(this.props.id);
    }
  }

  confirmUser = async (code) => {
    if (code) {
      try {
        const response = await AuthService.confirmUser(code);
        if(response.status === 200) {
          if(response.data) {
            this.setState({user: response.data.data.id, email: response.data.data.attributes.email});
            AuthService.logout();
          }
          this.setState({loading: false});
        }

      } catch (error) {
        this.setState({ error: error });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render(props) {
    if(!this.state.loading) {
      return (<Redirect replace to={{
        pathname: "/login",
        state: { token: this.props.id, user: this.state.user, email: this.state.email }
      }} />);
    } else {
      return null;
    }
  }
}
