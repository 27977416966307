import React from 'react';
import styled from 'styled-components';
import Logo from '../../atoms/Logo';
import Navigation from '../../organisms/Nav';
import CompanyPage from '../../pages/CompanyPage/new';
import { UserContext } from '../../../context/user';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 20px 15px;
  z-index: 10;
`;

const MainTemplate = (props) => {
  const { children } = props;
  return (
    <UserContext.Consumer>{user => (
      <Wrapper>
        <Header>
          <Logo />
          <Navigation confirmed={user && user.relationships.company.data} email={user && user.attributes.email} />
        </Header>
        {(user && user.relationships.company.data) && children}
        {(user && !user.relationships.company.data) && <CompanyPage user={user} />}
      </Wrapper>
    )}</UserContext.Consumer>
  );
};

export default MainTemplate;
