import React from 'react';
import MainTemplate from '../../templates/MainTemplate';
import Card from '../../atoms/StatBox';

import styled from 'styled-components';
import {ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, ReferenceLine} from 'recharts';

const newClients = [
  {
    name: '10', phone: 400, email: 240, 
  },
  {
    name: '11', phone: 300, email: 138, 
  },
  {
    name: '12', phone: 200, email: 980, 
  },
  {
    name: '13', phone: 278, email: 308, 
  },
  {
    name: '14', phone: 189, email: 480, 
  },
  {
    name: '15', phone: 239, email: 380, 
  },
  {
    name: '16', phone: 349, email: 430, 
  },
  {
    name: '17', phone: 349, email: 430, 
  },
];
const BonusActivity = [
  {
    name: '10', plus: 400, minus: -240, 
  },
  {
    name: '11', plus: 300, minus: -138, 
  },
  {
    name: '12', plus: 1200, minus: -980, 
  },
  {
    name: '13', plus: 278, minus: -308, 
  },
  {
    name: '14', plus: 189, minus: 0, 
  },
  {
    name: '15', plus: 239, minus: -380, 
  },
  {
    name: '16', plus: 349, minus: -430, 
  },
  {
    name: '17', plus: 349, minus: -430, 
  },
];
const loyaltyData = [
  {name: '1', count: 50},
  {name: '2', count: 77},
  {name: '3-5', count: 17},
  {name: '5-10', count: 18},
  {name: '10+', count: 23},
];

const BonusTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="tooltip">
        <p className="label">{`${label} июля`}</p>
        <span className={`label-${payload[0].name}`}>{`${payload[0].value} баллов начислено клиентам`}</span>
        <br/>
        <span className={`label-${payload[1].name}`}>{`${payload[1].value * -1} баллов списано`}</span>
      </div>
    );
  }

  return null;
};

const ClientsTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="tooltip">
        <p className="label">{`${label} июля`} зарегистрировано</p>
        <span className={`label-${payload[0].name}`}>{`${payload[0].value} с помощью телефона`}</span>
        <br/>
        <span className={`label-${payload[1].name}`}>{`${payload[1].value} с помощью email`}</span>
      </div>
    );
  }

  return null;
};

const LTVTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="tooltip">
        <p>{`${payload[0].value} клиентов купили `}
        {`${label} раз`}</p>
      </div>
    );
  }

  return null;
};

const Dashboard = styled.div`
  display: flex;
  max-width: 1400px;
  margin: auto;
  flex-wrap: wrap;

  .section {
    width: 48%;
    box-sizing: border-box;
    margin-left: 1%;
    margin-right: 1%;
    margin-bottom: 20px;
    background: #fff;
    padding: 10px 20px 20px;
    box-shadow: 0 8px 18px 0 rgba(88,88,88,0.1);
  }

  @media (max-width: 650px) {
    .section {
      width: 98%;
    }

    h3 {
      font-size: 17px;
      margin: 10px 0 5px;
    }
  }

  @media (prefers-color-scheme: dark) {
    .section {
      background: #2e2e2e;
      box-shadow: 0 7px 28px 0 rgba(0,0,0,0.2);
    }
  }
`;

const ReportsPage = () => (
  <MainTemplate>
    <Dashboard>
      <div className='section'>
        <h3>Активность за <button className='dropdown'>7 дней</button></h3>
        <Card title='Всего бонусов у клиентов' value='17 178₽' />
        <Card title='Бонусов списано' value='3 178₽' />
        <ResponsiveContainer width='100%' height={250}>
          <BarChart data={BonusActivity} stackOffset="sign">
            <XAxis dataKey="name"/>
            <YAxis/>
            <Tooltip content={<BonusTooltip />} />
            <ReferenceLine y={0} stroke='#000'/>
            <Bar dataKey="plus" fill="#009D64" stackId="stack" />
            <Bar dataKey="minus" fill="#E64300" stackId="stack" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className='section'>
        <h3>Новые клиенты за <button className='dropdown'>7 дней</button></h3>
        <ResponsiveContainer width='100%' height={320}>
          <BarChart data={newClients}>
            <XAxis dataKey="name"/>
            <YAxis/>
            <Tooltip content={<ClientsTooltip />} />
            <Bar dataKey="email" barSize={15} fill="#3f74ff" />
            <Bar dataKey="phone" barSize={15} fill="#009D64" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className='section'>
        <h3>Участники программы лояльности</h3>
        <Card title='Всего участников' value='17 178' />
        <Card title='Средний возраст' value='23-24' />
        <Card title='Мужчины' value='1 378' />
        <Card title='Женщины' value='1 688' />
      </div>

      <div className='section'>
        <h3>Частота покупок клиентов</h3>
        <ResponsiveContainer width='100%' height={200}>
          <BarChart data={loyaltyData}
              margin={{top: 5, right: 30, left: 20, bottom: 5}}>
            <XAxis dataKey="name"/>
            <YAxis />
            <Tooltip content={<LTVTooltip/>} />
            <Bar dataKey="count" fill="#3f74ff" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className='section'>
        <h3>Каналы связи</h3>
        <div className='grid-3'>
          <Card title='Email' value='1 578' />
          <Card title='Телефон' value='1 618' />
          <Card title='Telegram' value='1 008' />
        </div>
      </div>
      <div className='section'>
        <h3>Сертификаты</h3>
        <div className='grid-3'>
          <Card title='Продано на сумму' value='30 000₽' />
          <Card title='Использовано' value='20 000₽' />
        </div>
      </div>

    </Dashboard>
    {/*<details className='form'>
      <summary>Ключевые KPI программ лояльности</summary>
      <p>Коэффициент участия (Participation rate) – Соотношение количества  участников программы и общего количества клиентов</p>
      <p>Коэффициент активности (Activity rate)  – Процент участников, принимающих активное участие в программе</p>
      <p>Срок (Tenure)  – Длительность участия в программе</p>
      <p>Период неактивности (Inactivity Period) – интервал времени, прошедший с момента последней активности участника  в программе. Как правило, составляет 12-18 месяцев.</p>
      <p>Уровень оттока (Churn rate) – соотношение количества участников, покинувших программу к общему количеству участников. Отток фиксируется в случае, если период неактивности превышает пороговое значение.</p>
      <p>Коэффициент погашения (Redemption rate) – соотношение погашенных и начисленных бонусных единиц на определенный момент времени.</p>
      <p>Коэффициент сгорания (Breaking rate) – процент бонусных единиц, которые будут списаны в связи с истечением сроком действия и других правил, существующих в программе.</p>
      <p>Средняя стоимость погашения (Average spending per transaction) – среднее количество балов, потраченных на приобретение вознаграждения из каталога призов за одну транзакцию.</p>
      <p>Средняя стоимость вознаграждения  (Average reward price)– средняя стоимость вознаграждения в расчете на бонусные единицы, принятые в программе.</p>
      <p>Средняя стоимость бонусной единицы (Cost-per-point) – стоимость бонусной единицы программы в денежном выражении.</p>
      <p>Средний расход участника (Average member spending per transaction) – средняя сумма бонусных единиц, погашенных за транзакцию.</p>
      <p>Коэффициент стоимости программы (Loyalty program percentage cost) – соотношение совокупной стоимости бонусных единиц, начисленных участникам к общему доходу, генерируемому участниками программы.</p>
      <p>Добавленная ценность программы (Loyalty Uplift) – мера измерения дополнительной пользы программы лояльности для компании. Методика измерения добавленной ценности зависит от специфики программы.  Обзор способов расчета рассмотрен в данном документе. Как правило, ценность программы приводится к финансовым показателям.</p>
    </details>*/}
  </MainTemplate>
);

export default ReportsPage;
