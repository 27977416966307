import React from 'react';
import List from '../../organisms/List/static';
import MainTemplate from '../../templates/MainTemplate';

const DataSource = {"data": [{"type": "certificate", "id": "1", "attributes": {"name": "Подарочный сертификат (пластик)", "amount": 1000, "sold": "10", "activated": "3", "source": "магазины", "kind": "plastic", "created_at": "2020-01-06T19:24:14.234Z"}, }, {"type": "certificate", "id": "2", "attributes": {"name": "Подарочный сертификат (пластик)", "amount": 2000, "sold": "0", "activated": "0", "source": "магазины", "kind": "plastic", "created_at": "2020-01-06T19:24:14.234Z"}, }, {"type": "certificate", "id": "3", "attributes": {"name": "Подарочный сертификат (пластик)", "amount": 3000, "sold": "0", "activated": "0", "source": "магазины", "kind": "plastic", "created_at": "2020-01-06T19:24:14.234Z"}, }, {"type": "certificate", "id": "4", "attributes": {"name": "Подарочный сертификат (пластик)", "amount": 500, "sold": "0", "activated": "0", "source": "магазины", "kind": "plastic", "created_at": "2020-01-06T19:24:14.234Z"}, }, {"id": "5", "type": "certificate", "attributes": {"name": "Электронная подарочная карта", "amount": 500, "sold": "10", "activated": "5", "source": "интернет-магазин", "kind": "digital", "created_at": "2020-01-08T07:41:05.452Z"}, }, {"id": "6", "type": "certificate", "attributes": {"name": "Электронная подарочная карта", "amount": 1000, "sold": "5", "activated": "3", "source": "интернет-магазин", "kind": "digital", "created_at": "2020-01-08T07:41:05.452Z"}, }, {"id": "7", "type": "certificate", "attributes": {"name": "Электронная подарочная карта", "amount": 1500, "sold": "3", "activated": "1", "source": "интернет-магазин", "kind": "digital", "created_at": "2020-01-08T07:41:05.452Z"}, } ] }
// const DataSource = {"data":[]}
const CertificatesPage = () => (
  <MainTemplate>
    <List className='scrollable' data={DataSource} options={['', '', '', '', '', {'plastic':'пластиковая', 'digital':'электронная'}, '', '']} items="certificates" title="Подарочные сертификаты" headers={["Название группы", "номинал", "продано", "использовано", "точки продаж", "тип", "дата создания"]} actions={true}>
      <h2>Начните раздавать сертификаты</h2>
      <p>Настраивайте кросс-маркетинг с компаниями и повышайте средний чек с помощью сертификатов</p>
    </List>
  </MainTemplate>
);

export default CertificatesPage;
